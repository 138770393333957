import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './Home.css';
import Authentication from './Authentication';

const Home = () => {
  useEffect(() => {
    document.title = 'GLEEM.AI';
  }, []);

  return (
    <>
      <div className="logo-container">
        <Link className="logo" to="/">
          <img src="/logo192.png" width="64"></img>
          {/* <p>gleem.ai</p> */}
        </Link>
      </div>
      <div className="home-container">
        <Authentication />
        {/* <img
          src="/hero.png"
          alt="hero-1"
          className="hero"
          style={{ clipPath: 'polygon(20% 0, 100% 0%, 100% 100%, 0 100%)' }}
        /> */}
      </div>
    </>
  );
};

export default Home;
