import React, { forwardRef, useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { LinearProgress } from '@mui/material';

export default function Toast({ alert, setAlert }) {
  const [progress, setProgress] = useState(100);
  const [open, setopen] = useState(false);
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const startProgress = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress - 0.25);
    }, 10);

    const duration = setTimeout(() => {
      console.log('end', progress);
      clearInterval(interval);
      setProgress(100);
      setAlert({ ...alert, show: false });
    }, 6200);

    return () => clearTimeout(duration);
  };

  useEffect(() => {
    alert.show && startProgress();
  }, [alert.show]);

  const onClose = () => {
    setProgress(100);
    setAlert({ ...alert, show: false });
  };
  return (
    <>
      <Snackbar
        open={alert.show}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        onClose={onClose}
        sx={{ zIndex: '99999', marginTop: '50px' }}
      >
        <Alert
          onClose={() => setAlert({ ...alert, show: false })}
          severity={alert.severity}
          sx={{ width: '100%', zIndex: '99999' }}
        >
          <>
            {alert.msg}
            <LinearProgress value={progress} variant="determinate" />
          </>
        </Alert>
      </Snackbar>
    </>
  );
}
