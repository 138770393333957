import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ButtonGroup } from '@mui/material';
import ImageDialog from '../ImageDialog';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';

import { AddAPhoto, Delete } from '@mui/icons-material';
import SettingsIcon from '@mui/icons-material/Settings';

import { convertBase64 } from '../../../utils/generate';
import { speciesValues, genderValues } from '../../../data';
import Dropdown from '../Generate/Dropdown';
import { useCms } from '../../../utils/CMS';

export default function ProductImages({ name }) {
  const [open, setOpen] = useState(false);
  const [files, setfiles] = useState([]);
  const [images, setImages] = useState([]);
  const [thumb, setthumb] = useState('');

  const { productSamples, setProductSamples } = useCms();

  const [selectedSpecies, setSelectedSpecies] = useState('human');
  const [selectedGender, setSelectedGender] = useState('male');

  const [loading, setloading] = useState(true);

  useEffect(() => {
    open &&
      productSamples(name).then((imgs) => {
        setloading(true);
        const samples = imgs[0].images.samples.map((sample) => {
          var item = {
            img: sample.img,
            species: sample.species,
            gender: sample.gender,
            thumb: false,
          };
          return item;
        });
        if (imgs[0].images.thumb.length !== 0) {
          const thumb = { ...imgs[0].images.thumb[0] };
          setImages([thumb, ...samples]);
        } else {
          setImages([...samples]);
        }
        setloading(false);
      });
  }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = (files) => {
    const arr = [...files];
    arr.map((file) => {
      return convertBase64(file).then((img) => {
        var item = {
          img: img,
          species: selectedSpecies,
          gender: selectedGender,
          thumb: false,
        };
        setImages((current) => [...current, item]);
      });
    });
  };

  const handleSave = () => {
    setOpen(false);
    const samples = images.filter((img) => {
      return img.thumb === false;
    });
    const thumb = images.filter((img) => {
      return img.thumb === true;
    });
    console.log(thumb);

    setProductSamples(name, samples, thumb).then((resp) => {
      if (resp.success) {
        console.log('success');
      }
    });
  };

  return (
    <>
      <div onClick={handleClickOpen}>
        <AddAPhoto />
        Add images
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Select product thumbnail and sample images </DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <Box
              sx={{
                mt: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <input
                type="file"
                onChange={(e) => handleUpload(e.target.files)}
                multiple
                accept="image/*"
              />
              <Box
                sx={{
                  mt: 2,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 1,
                }}
              >
                <Dropdown
                  name="Species"
                  value={selectedSpecies}
                  setValue={setSelectedSpecies}
                  items={speciesValues}
                />
                <Dropdown
                  name="Gender"
                  value={selectedGender}
                  setValue={setSelectedGender}
                  items={genderValues}
                />
              </Box>
            </Box>
            {loading ? (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: 20,
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              images.length > 0 && (
                <Gallery images={images} setImages={setImages} />
              )
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="filled"
            onClick={handleClose}
            sx={{ margin: '10px' }}
          >
            CANCEL
          </Button>
          <Button variant="filled" onClick={handleSave} sx={{ margin: '10px' }}>
            SAVE
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function Gallery({ images, setImages }) {
  const handleDelete = (idx) => {
    var temp = [...images];
    temp.splice(idx, 1);
    setImages(temp);
  };

  const handlesethumb = (idx) => {
    var temp = [...images];
    setImages(
      temp.map((img, index) => {
        return index === idx
          ? { ...img, thumb: true }
          : { ...img, thumb: false };
      })
    );
  };

  return (
    <>
      <ImageList
        sx={{
          width: '100%',
          height: 'auto',
          overflow: 'hidden',
          paddingTop: '50px',
        }}
        cols={6}
        rowHeight={300}
        gap={20}
      >
        {images.map((item, idx) => {
          return (
            <ImageListItem key={idx} sx={{}}>
              <Box>
                {item.thumb && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '10px',
                      left: '-20px',
                      backgroundColor: '#fecd27',
                      transform: 'rotate(-45deg)',
                      color: '#000',
                      padding: '0px 20px',
                    }}
                  >
                    THUMB
                  </div>
                )}
                <img src={item.img} alt="" width="300" key={idx} />
                <ImageListItemBar
                  title={item.species}
                  subtitle={item.gender}
                  actionIcon={
                    <ButtonGroup>
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255)' }}
                        onClick={() => handlesethumb(idx)}
                      >
                        <AddAPhoto />
                      </IconButton>
                      <IconButton
                        sx={{ color: 'rgba(255, 255, 255)' }}
                        onClick={() => handleDelete(idx)}
                      >
                        <Delete />
                      </IconButton>
                    </ButtonGroup>
                  }
                />
              </Box>
            </ImageListItem>
          );
        })}
      </ImageList>
    </>
  );
}
