import { Box } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableCell } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';

{
  /* 
Name
category
product
thumbnail
gender
author
Created at
Rating
Production
Actions
 */
}
const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    align: 'left',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    label: 'Category',
    align: 'center',
  },
  {
    id: 'product',
    numeric: false,
    disablePadding: false,
    label: 'Product',
    align: 'center',
  },
  {
    id: 'thumb',
    numeric: false,
    disablePadding: false,
    label: 'Thumbnail',
    align: 'center',
  },
  {
    id: 'species',
    numeric: false,
    disablePadding: false,
    label: 'Species',
    align: 'center',
  },
  // {
  //   id: 'gender',
  //   numeric: false,
  //   disablePadding: false,
  //   label: 'Gender',
  //   align: 'center',
  // },

  {
    id: 'author',
    numeric: false,
    disablePadding: false,
    label: 'Author',
    align: 'center',
  },
  {
    id: 'modified by',
    numeric: false,
    disablePadding: false,
    label: 'Modified by',
    align: 'center',
  },
  {
    id: 'created at',
    numeric: false,
    disablePadding: false,
    label: 'Created at',
    align: 'center',
  },
  {
    id: 'rating',
    numeric: false,
    disablePadding: false,
    label: 'Rating',
    align: 'center',
  },
  {
    id: 'production',
    numeric: false,
    disablePadding: false,
    label: 'Production',
    align: 'center',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Actions',
    align: 'center',
  },
];

export default function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) =>
          headCell.id === 'name' ? (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                sx={{ '&:hover': { color: '#fff' } }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span">
                    {order === 'desc' ? (
                      <NorthIcon sx={{ fontSize: '13px' }} />
                    ) : (
                      <SouthIcon sx={{ fontSize: '13px' }} />
                    )}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}
