import { useEffect, useState } from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './components/Home';
import Dashboard from './components/Dashboard/Dashboard';

import './App.css';
import GuardedRoute from './components/GuarderRoute';
import TestRoute from './components/TestRoute';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route
            path="dashboard"
            element={
              <GuardedRoute>
                <Dashboard />
              </GuardedRoute>
            }
          />
          <Route path="test" element={<TestRoute />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
