import { useState, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';

import { Button, Container, IconButton, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';

import LinearProgressWithLabel from './ProgressBar';
import LinearProgress from '@mui/material/LinearProgress';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import CasinoIcon from '@mui/icons-material/Casino';
import RecyclingIcon from '@mui/icons-material/Recycling';
import DeleteIcon from '@mui/icons-material/Delete';

import SimpleAccordion from './Accordian';
import SmallDialog from './SmallDialog';
import FullScreenDialog from './FullScreenDialog';
import Gallery from './Carousel';
import Toast from '../Toast';
import PromptHistoryDialog from './PromptHistoryDialog';
import StepedSlider from './StepedSlider';
import LoraDialog from './LoraDialog';
import Dropdown from './Generate/Dropdown';

import { useAuth } from '../../utils/Auth';
import { useCms } from '../../utils/CMS';
import {
  savePromptLocally,
  deletePromptLocally,
  getPromptFromStorage,
  getDateAndTime,
  getRandomInt,
  delay,
  recursiveGenerate,
} from '../../utils/generate';

import useSse from '../../hooks/useSse';

import { speciesValues, genderValues, productionValues } from '../../data';
import PromptsList from './PromptList/PromptsList';
import SaveAs from './Generate/SaveAs';
import ProductGenerate from './Generate/ProductGenerate';

const Generate = ({ user }) => {
  const {
    generate,
    generate2,
    isAdmin,
    getProgress,
    status,
    models,
    loras,
    samplers,
    getLoraThumb,
    getLorasList,
    getImages,
    username,
  } = useAuth();
  const {
    getTree,
    addSubcategory,
    addPrompt,
    updatePrompt,
    getPromptByName,
    updateHistory,
    getHistory,
    getAllCategories,
    getAllGroups,
  } = useCms();

  const { data, setStarted, settotalJobs } = useSse(
    `${username.split('@')[0]}_generate`
  );

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const promptRef = useRef();
  const npromptRef = useRef();

  const [isLoading, setisLoading] = useState(false);
  const [resultImage, setResultImage] = useState();
  const [resultSeeds, setResultSeeds] = useState();

  const [progress, setProgress] = useState(0);

  const [checkpoints, setcheckpoints] = useState([]);
  const [lorasList, setlorasList] = useState([]);

  const [checkpoint, setCheckpoint] = useState('1_sd_xl_base_1.0');
  const [lora, setLora] = useState('');
  const [loraDialogOpen, setLoraDialogOpen] = useState(false);
  const [selectedLoras, setSelectedLoras] = useState([]);
  const [selectedLorasCount, setselectedLorasCount] = useState({
    male: 0,
    female: 0,
    cat: 0,
    dog: 0,
  });
  const [loraStrength, setLoraStrength] = useState(1);

  const [sdVersion, setSdVersion] = useState('xl');
  const [versionSwitch, setVersionSwitch] = useState(false);
  const [license, setLicense] = useState(false);

  const [width, setWidth] = useState(1024);
  const [height, setHeight] = useState(1024);
  const [steps, setSteps] = useState(20);
  const [batchSize, setbatchSize] = useState(1);
  const [seed, setseed] = useState(1);
  const [prompt, setPrompt] = useState('');
  const [nprompt, setNPrompt] = useState('');
  const [cfgValue, setCfgValue] = useState(7);
  const [sampler, setSampler] = useState('');
  const [hires, setHires] = useState(false);
  const [refiner, setRefiner] = useState(false);
  const [upscaleBy, setUpscaleBy] = useState(1.5);
  const [refinerSteps, setrefinerSteps] = useState(20);

  const [promptHistoryList, setpromptHistoryList] = useState([]);
  const [promptId, setpromptId] = useState('');

  const [batchStatus, setbatchStatus] = useState(1);
  const [loraThumbsList, setLoraThumbsList] = useState({});
  const [productGeneration, setproductGeneration] = useState(false);
  const [productsCount, setproductsCount] = useState(0);

  const [downloadImages, setdownloadImages] = useState(false);

  const [quickLoraSelect, setquickLoraSelect] = useState(false);

  // LOAD PROMPT FROM LOCAL STORAGE IF EXIST
  useEffect(() => {
    const p = getPromptFromStorage();
    if (p) {
      if (!p.loaded) {
        setSdVersion(p.checkpointVersion);

        setCheckpoint(p.checkpoint);
        setWidth(p.width);
        setHeight(p.height);
        setSteps(p.steps);
        setseed(p.seed);
        setCfgValue(p.cfg);
        setSampler(p.sampler ? p.sampler : 'Euler a');
        setPrompt(p.prompt);
        setNPrompt(p.nprompt);
        setpromptHistoryList(p.history);
      } else {
        setloadedPromptName(p.name);
        setLoadedPromptGroup(p.parent);
        setLoadedPromptCategory(p.category);
        setCheckpoint(p.checkpoint);
        setCheckpoint(p.checkpoint);
        setWidth(p.width);
        setHeight(p.height);
        setSteps(p.steps);
        setseed(p.seed);
        setCfgValue(p.cfg);
        setSampler(p.sampler ? p.sampler : 'Euler a');
        // setPrompt(p.prompt);
        promptRef.current.value = p.prompt;
        setNPrompt(p.nprompt);
        setPromptToLoad('');
        setpromptHistoryList(p.history);

        getPromptByName(p.name).then((resp) => {
          if (resp.success) {
            setpromptId(resp.msg);
          } else {
            console.error(resp.msg);
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    if (loras && models) {
      filterByVersion();
    }
  }, [models, loras]);

  // old download method
  // useEffect(() => {
  //   if (data) {
  //     console.log(resultImage);
  //     getImages(data.message, batchSize).then((imgs) => {
  //       console.log('images downloaded');
  //       console.log(loraThumbsList);
  //       const i = [loraThumbsList[data.message], ...imgs.images];
  //       const seeds = [0, ...imgs.seeds];
  //       setResultImage((current) => [...current, ...i]);
  //       setResultSeeds((current) => [...current, ...seeds]);
  //       setbatchStatus((current) => current + 1);
  //     });
  //   }
  // }, [data]);

  useEffect(() => {
    if (data) {
      if (productGeneration) {
        const imgs = data;
        console.log('images downloaded');
        imgs.images = new Array(parseInt(batchSize))
          .fill()
          .map(
            (element, index) =>
              `https://devrest.garagefarm.net/images/${imgs.uuid}/${index}`
          );
        imgs.seeds = new Array(parseInt(batchSize))
          .fill()
          .map((element, index) => 666);
        const i = [loraThumbsList[imgs.uuid], ...imgs.images];
        const seeds = [0, ...imgs.seeds];
        setResultImage((current) => [...current, ...i]);
        setResultSeeds((current) => [...current, ...seeds]);
        setbatchStatus((current) => current + 1);
      } else {
        var batchx = 5;
        if (selectedLoras.length > 1) {
          batchx = parseInt(batchSize);
        } else {
          batchx = 5;
        }
        const imgs = data;
        console.log(`job: ${imgs.uuid} - batch: ${batchx}`);
        imgs.images = new Array(batchx)
          .fill()
          .map(
            (element, index) =>
              `https://devrest.garagefarm.net/images/${imgs.uuid}/${index}`
          );
        console.log(imgs.images);
        imgs.seeds = new Array(parseInt(batchx))
          .fill()
          .map((element, index) => 666);
        const i = [loraThumbsList[imgs.uuid], ...imgs.images];
        console.log(i);
        const seeds = [0, ...imgs.seeds];
        setResultImage((current) => [...current, ...i]);
        setResultSeeds((current) => [...current, ...seeds]);
        setbatchStatus((current) => current + 1);
      }
    }
  }, [data]);

  useEffect(() => {
    if (resultImage) {
      if (productGeneration) {
        if (selectedLoras.length > 1) {
          resultImage.length ===
            selectedLoras.length * (5 * productsCount) + productsCount &&
            setisLoading(false);
        } else {
          resultImage.length === 5 * productsCount + productsCount &&
            setisLoading(false);
        }
      } else {
        console.log(`result image length = ${resultImage.length}`);
        if (selectedLoras.length > 1) {
          resultImage.length ===
            selectedLoras.length * (parseInt(batchSize) + 1) &&
            setisLoading(false);
        } else {
          const totalImages =
            Math.ceil(batchSize / 5) * 5 + Math.ceil(batchSize / 5);
          resultImage.length === totalImages && setisLoading(false);
        }
      }
    }
  }, [resultImage]);

  useEffect(() => {
    filterByVersion();
    if (versionSwitch) {
      setSdVersion('2.1');
    } else {
      setSdVersion('xl');
    }
  }, [versionSwitch]);

  useEffect(() => {
    filterByVersion();
  }, [license]);

  const handleSubmit = (event) => {
    console.clear();
    event.preventDefault();
    setResultImage([]);
    setResultSeeds([]);
    setLoraThumbsList([]);
    const formData = new FormData(event.currentTarget);
    var data = {
      prompt: promptRef.current.value, //formData.get('prompt'),
      nprompt: formData.get('nprompt'),
      steps: formData.get('steps'),
      batch: formData.get('batch'),
      width: formData.get('width'),
      height: formData.get('height'),
      seed: formData.get('seed'),
      cfg: cfgValue,
      checkpoint: checkpoint,
      sampler: sampler,
      hires: hires,
      refiner: refiner,
      refinerSteps: refinerSteps,
      upscaleBy: upscaleBy !== '' ? upscaleBy : '1.5',
    };

    !promptHistoryList.includes(data.prompt) &&
      setpromptHistoryList((current) => [...current, data.prompt]);
    if (!promptId) {
      const p = {
        ...data,
        history: [...promptHistoryList, data.prompt],
        checkpointVersion: sdVersion,
        loaded: promptId ? true : false,
      };
      savePromptLocally(p);
    } else {
      const d = getPromptFromStorage();
      const p = {
        ...d,
        ...data,
        checkpointVersion: sdVersion,
        history: [...promptHistoryList, data.prompt],
        loaded: true,
      };
      savePromptLocally(p);
    }

    setisLoading(true);
    if (selectedLoras.length > 1) {
      setStarted(true);
      settotalJobs(selectedLoras.length);
      console.log(typeof setLoraThumbsList);
      selectedLoras.forEach((e, idx) => {
        recursiveGenerate(
          e,
          idx,
          data,
          generate,
          setProgress,
          setisLoading,
          setLoraThumbsList,
          loraThumbsList,
          getLoraThumb
        );
      });
    } else {
      data.prompt =
        data.prompt.replace('\n') +
        ` <lora:${selectedLoras[0].name}:${selectedLoras[0].strength}>`;
      data.lora = selectedLoras[0].name;
      generate2(data)
        .then((resp) => {
          console.log(resp);
          setStarted(true);
          settotalJobs(resp.task_id.length);
          const thumb = `https://devrest.garagefarm.net/${selectedLoras[0].user}/avatar/${selectedLoras[0].name}`;
          resp.task_id.forEach((uuid) => {
            setLoraThumbsList((current) => ({ ...current, [uuid]: thumb }));
          });

          setAlert({
            show: true,
            severity: 'success',
            msg: 'Job added',
          });
        })
        .catch((e) => {
          console.error(e);
        });
    }
    setbatchStatus(1);
    // setisLoading(false);
  };

  const filterByVersion = () => {
    const filteredModels = [];
    const filteredLoras = [];
    if (versionSwitch) {
      models.map((model) => {
        if (model.model_name.startsWith('2')) {
          filteredModels.push(model);
        } else if (license && model.model_name.startsWith('no_licence_2')) {
          filteredModels.push(model);
        }
      });
      loras.map((lora) => {
        if (lora.version === '2') {
          filteredLoras.push(lora);
        }
      });
    } else {
      models.map((model) => {
        if (model.model_name.startsWith('1')) {
          filteredModels.push(model);
        } else if (license && model.model_name.startsWith('no_licence_1')) {
          filteredModels.push(model);
        }
      });
      loras.map((lora) => {
        if (lora.version === 'xl') {
          filteredLoras.push(lora);
        }
      });
    }

    setcheckpoints(filteredModels);
    setlorasList(filteredLoras);
  };

  const handleSetLora = (lora) => {
    setLora(lora);
    // setPrompt((current) => `${current} <lora:${event.target.value}:0.8>`);
  };
  const handleLoraOpen = () => {
    setLoraDialogOpen(true);
  };

  const handleLoraClose = (value, strength) => {
    setLoraDialogOpen(false);
    setSelectedLoras(value);
    countSelected(value);
    setLoraStrength(strength);
    console.log(loraStrength);
  };

  const countSelected = (value) => {
    var selected = { male: 0, female: 0, cat: 0, dog: 0 };
    value.forEach((l) => {
      if (l.selected) {
        selected[l.sex] += 1;
      }
    });
    setselectedLorasCount(selected);
  };

  const handleQuickLoraSelect = () => {
    if (quickLoraSelect) {
      const sg = selectedSpecies === 'human' ? selectedGender : selectedSpecies;
      const filtered = lorasList
        .filter((lora) => lora.user === 'adam.kosiorek' && lora.sex === sg)
        .map((l) => ({ ...l, selected: true, strength: loraStrength }));
      countSelected(filtered);
      setSelectedLoras(filtered);
    } else {
      var selected = { male: 0, female: 0, cat: 0, dog: 0 };
      setselectedLorasCount(selected);
      setSelectedLoras([]);
    }
  };

  // SEED UTILS
  const handleSeedChange = (event) => {
    setseed(event.target.value);
  };
  const handleRandomSeed = () => {
    setseed(-1);
  };
  const addToSeedList = (value) => {
    if (seedsList.includes(value)) {
      setAlert({
        show: true,
        severity: 'warning',
        msg: 'Seed already on list',
      });
    } else {
      setseedsList((current) => [...current, value]);
      setAlert({
        show: true,
        severity: 'success',
        msg: 'Seed saved',
      });
    }
  };

  const handleSetHires = (checked) => {
    setHires(checked);

    if (checked) {
      setbatchSize(1);
      setAlert({
        show: true,
        severity: 'warning',
        msg: 'Batch size changed to 1',
      });
    }
  };
  const handleSetRefiner = (checked) => {
    setRefiner(checked);

    // if (checked) {
    //   setbatchSize(1);
    //   setAlert({
    //     show: true,
    //     severity: 'warning',
    //     msg: 'Batch size changed to 1',
    //   });
    // }
  };

  const handleSetRefinerSteps = (value) => {
    if (value > 50) {
      setrefinerSteps(50);
    } else if (value < 0) {
      setrefinerSteps(0);
    } else {
      setrefinerSteps(value);
    }
  };

  // SPECIES & GENDER handlers + update multi prompt values

  const handleSetSpecies = (value) => {
    setSelectedSpecies(value);
    const key = `${selectedSpecies}_${selectedGender}`;
    setprompts((current) => ({ ...current, [key]: promptRef.current.value }));
    setnprompts((current) => ({ ...current, [key]: nprompt }));

    setmultiParams((current) => ({
      lorastrength: { ...current.lorastrength, [key]: loraStrength },
      steps: { ...current.steps, [key]: steps },
      cfg: { ...current.cfg, [key]: cfgValue },
      refiner: { ...current.refiner, [key]: refiner },
      refinersteps: { ...current.refinersteps, [key]: refinerSteps },
      sampler: { ...current.sampler, [key]: sampler },
    }));
    handleQuickLoraSelect();
  };
  const handleSetGender = (value) => {
    setSelectedGender(value);
    const key = `${selectedSpecies}_${selectedGender}`;
    setprompts((current) => ({ ...current, [key]: promptRef.current.value }));
    setnprompts((current) => ({ ...current, [key]: nprompt }));

    setmultiParams((current) => ({
      lorastrength: { ...current.lorastrength, [key]: loraStrength },
      steps: { ...current.steps, [key]: steps },
      cfg: { ...current.cfg, [key]: cfgValue },
      refiner: { ...current.refiner, [key]: refiner },
      refinersteps: { ...current.refinersteps, [key]: refinerSteps },
      sampler: { ...current.sampler, [key]: sampler },
    }));
    handleQuickLoraSelect();
  };

  //Prompt curation
  ////////////////////////////////////////////////////////////////////

  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [saveDialogData, setSaveDialogData] = useState();

  const [promptListDialogOpen, setPromptListDialogOpen] = useState(false);

  const [promptName, setPromptName] = useState('');

  const [categoriesTree, setCategoriesTree] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [newGroupName, setNewGroupName] = useState('');

  const [promptToLoad, setPromptToLoad] = useState('');
  const [loadedPromptName, setloadedPromptName] = useState('');
  const [loadedPromptCategory, setLoadedPromptCategory] = useState();
  const [loadedPromptGroup, setLoadedPromptGroup] = useState();
  const [promptAuthor, setpromptAuthor] = useState('');

  const [promptThumb, setPromptThumb] = useState('');

  const [savePromptBlocked, setsavePromptBlocked] = useState(true);
  const [cachedPromptToLoad, setCachedPromptToLoad] = useState('');

  const [selectedSpecies, setSelectedSpecies] = useState('human');
  const [selectedGender, setSelectedGender] = useState('male');
  const [isProduction, setIsProduction] = useState('No');

  const [seedDialogOpen, setseedDialogOpen] = useState(false);
  const [seedDialogData, setseedDialogData] = useState();
  const [seedsList, setseedsList] = useState([]);

  const [prompts, setprompts] = useState({
    human_male: '',
    human_female: '',
    cat_male: '',
    cat_female: '',
    dog_male: '',
    dog_female: '',
  });
  const [nprompts, setnprompts] = useState({
    human_male: '',
    human_female: '',
    cat_male: '',
    cat_female: '',
    dog_male: '',
    dog_female: '',
  });
  const [multiParams, setmultiParams] = useState({
    lorastrength: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    steps: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    cfg: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    refiner: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    refinersteps: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
    sampler: {
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    },
  });

  useEffect(() => {
    handleQuickLoraSelect();
  }, [quickLoraSelect, selectedSpecies, selectedGender]);

  useEffect(() => {
    document.title = `${loadedPromptGroup} > ${loadedPromptName} - ${selectedSpecies} ${selectedGender}`;
  }, [loadedPromptName, selectedSpecies, selectedGender]);

  // SAVE PROMPT AS
  useEffect(() => {
    if (saveDialogData) {
      console.log('save prompt');
      const key = `${selectedSpecies}_${selectedGender}`;
      setprompts((current) => ({ ...current, [key]: promptRef.current.value }));
      setnprompts((current) => ({ ...current, [key]: nprompt }));

      const p = { ...prompts, [key]: promptRef.current.value };
      const np = { ...nprompts, [key]: nprompt };
      const params_temp = {
        lorastrength: { ...multiParams.lorastrength, [key]: loraStrength },
        steps: { ...multiParams.steps, [key]: steps },
        cfg: { ...multiParams.cfg, [key]: cfgValue },
        refiner: { ...multiParams.refiner, [key]: refiner },
        refinersteps: { ...multiParams.refinersteps, [key]: refinerSteps },
        sampler: { ...multiParams.sampler, [key]: sampler },
      };
      const createdAt = getDateAndTime();
      if (selectedGroup === '') {
        const newGroup = {
          name: newGroupName,
          parent: selectedCategory,
          production: false,
        };
        const newPrompt = {
          name: promptName,
          parent: newGroupName,
          category: selectedCategory,
          author: user,
          modified_by: user,
          createdAt: createdAt,
          checkpointVersion: sdVersion,
          checkpoint: checkpoint,
          width: width,
          height: height,
          steps: steps,
          seed: seedsList,
          cfg: cfgValue,
          sampler: sampler,
          prompt: promptRef.current.value, //prompt,
          nprompt: nprompt,
          thumb: promptThumb,
          creator_rating: 0,
          reviewer_rating: 0,
          gender: selectedGender,
          species: selectedSpecies,
          isProduction: isProduction,
          loraStrength: loraStrength,
          note: [],
          prompts: p,
          nprompts: np,
          params: params_temp,
        };
        setloadedPromptName(promptName);
        setLoadedPromptGroup(newGroupName);
        setLoadedPromptCategory(selectedCategory);
        addSubcategory(newGroup).then((resp) => {
          if (resp.success) {
            addPrompt(newPrompt).then((resp) => {
              if (resp.success) {
                setAlert({
                  show: true,
                  severity: 'success',
                  msg: 'Prompt saved',
                });
                setSaveDialogData(false);
                getPromptByName(promptName).then((resp) => {
                  if (resp.success) {
                    updateHistory(resp.msg, promptHistoryList).then((resp) => {
                      if (resp.success) {
                        console.log('succes');
                      } else {
                        console.log(resp);
                      }
                    });
                  }
                });
              } else {
                setAlert({
                  show: true,
                  severity: 'error',
                  msg: resp.msg,
                });
                setSaveDialogData(false);
              }
            });
          } else {
            setAlert({
              show: true,
              severity: 'error',
              msg: resp.msg,
            });
            setSaveDialogData(false);
          }
        });
      } else {
        const newPrompt = {
          name: promptName,
          parent: selectedGroup,
          category: selectedCategory,
          author: user,
          modified_by: user,
          createdAt: createdAt,
          checkpointVersion: sdVersion,
          checkpoint: checkpoint,
          width: width,
          height: height,
          steps: steps,
          seed: seedsList,
          cfg: cfgValue,
          sampler: sampler,
          prompt: promptRef.current.value, //prompt,
          nprompt: nprompt,
          thumb: promptThumb,
          creator_rating: 0,
          reviewer_rating: 0,
          gender: selectedGender,
          species: selectedSpecies,
          isProduction: isProduction,
          loraStrength: loraStrength,
          note: [],
          prompts: p,
          nprompts: np,
          params: params_temp,
        };
        setloadedPromptName(promptName);
        setLoadedPromptGroup(selectedGroup);
        setLoadedPromptCategory(selectedCategory);

        addPrompt(newPrompt).then((resp) => {
          if (resp.success) {
            setAlert({
              show: true,
              severity: 'success',
              msg: 'Prompt saved',
            });
            setSaveDialogData(false);
            getPromptByName(promptName).then((resp) => {
              if (resp.success) {
                updateHistory(resp.msg, promptHistoryList).then((resp) => {
                  if (resp.success) {
                    console.log('succes');
                  } else {
                    console.log(resp);
                  }
                });
              }
            });
          } else {
            setAlert({
              show: true,
              severity: 'error',
              msg: resp.msg,
            });
            setSaveDialogData(false);
          }
        });
      }
    }
  }, [saveDialogData]);

  // UNLOCK SAVE PROMPT BUTTON
  useEffect(() => {
    if (promptName && selectedCategory && (selectedGroup || newGroupName)) {
      setsavePromptBlocked(false);
    } else {
      setsavePromptBlocked(true);
    }
  }, [promptName, selectedCategory, selectedGroup, newGroupName]);

  // LOADIN PROMPT AND SETTING DEPENDANT STATES
  useEffect(() => {
    if (promptToLoad) {
      const promptKey = `${promptToLoad.species}_${promptToLoad.gender}`;

      setPromptListDialogOpen(false);
      setloadedPromptName(promptToLoad.name);
      setLoadedPromptGroup(promptToLoad.parent);
      setLoadedPromptCategory(promptToLoad.category);
      setSelectedGroup(promptToLoad.parent);
      setSelectedCategory(promptToLoad.category);

      setpromptAuthor(promptToLoad.author);

      setPromptName(promptToLoad.name);
      // setSelectedCategory(promptToLoad.category);
      // setSelectedGroup(promptToLoad.parent);

      promptToLoad.checkpointVersion === '2.1'
        ? setVersionSwitch(true)
        : setVersionSwitch(false);
      setCheckpoint(promptToLoad.checkpoint);
      setWidth(promptToLoad.width);
      setHeight(promptToLoad.height);

      console.log(promptToLoad.seed);
      setseed(
        promptToLoad.seed instanceof Array
          ? promptToLoad.seed[0]
          : promptToLoad.seed
      );
      setseedsList(
        promptToLoad.seed instanceof Array
          ? promptToLoad.seed
          : [promptToLoad.seed]
      );

      setSampler(promptToLoad.sampler ? promptToLoad.sampler : 'Euler a');
      setPrompt(promptToLoad.prompt);

      setSelectedGender(
        'gender' in promptToLoad ? promptToLoad.gender : 'male'
      );
      setSelectedSpecies(
        'species' in promptToLoad ? promptToLoad.species : 'human'
      );
      setIsProduction(
        'isProduction' in promptToLoad ? promptToLoad.isProduction : 'No'
      );

      // MULTI PROMPT
      setprompts(promptToLoad.prompts);
      setnprompts(promptToLoad.nprompts);
      setmultiParams(promptToLoad.params);

      promptRef.current.value =
        promptToLoad.prompts[promptKey] !== ''
          ? promptToLoad.prompts[promptKey]
          : '';
      setNPrompt(
        promptToLoad.nprompts[promptKey] !== ''
          ? promptToLoad.nprompts[promptKey]
          : ''
      );
      setSteps(
        promptToLoad.params.steps[promptKey] !== ''
          ? promptToLoad.params.steps[promptKey]
          : '30'
      );
      setCfgValue(
        promptToLoad.params.cfg[promptKey] !== ''
          ? promptToLoad.params.cfg[promptKey]
          : '7'
      );
      setLoraStrength(
        promptToLoad.params.lorastrength[promptKey] !== ''
          ? promptToLoad.params.lorastrength[promptKey]
          : '1'
      );
      setRefiner(
        promptToLoad.params.refiner[promptKey] !== ''
          ? promptToLoad.params.refiner[promptKey]
          : false
      );
      setrefinerSteps(
        promptToLoad.params.refinersteps[promptKey] !== ''
          ? promptToLoad.params.refinersteps[promptKey]
          : '25'
      );
      setSampler(
        promptToLoad.params.sampler[promptKey] !== ''
          ? promptToLoad.params.sampler[promptKey]
          : 'Euler a'
      );

      setPromptToLoad('');

      getPromptByName(promptToLoad.name).then((resp) => {
        if (resp.success) {
          setpromptId(resp.msg);
          getHistory(resp.msg).then((resp) => {
            if (resp.success) {
              const history = [...resp.msg];
              const p = {
                ...promptToLoad,
                history: history,
                checkpointVersion: promptToLoad.checkpointVersion,
                loaded: true,
              };
              setpromptHistoryList(history);
              savePromptLocally(p);
            }
          });
        } else {
          console.error(resp.msg);
        }
      });
    }
  }, [promptToLoad]);

  // CLEAN PROMPT
  const handleRevertPrompt = () => {
    setWidth(1024);
    setHeight(1024);
    setSteps(20);
    setseed(1);
    setCfgValue(7);
    setPrompt('');
    promptRef.current.value = '';
    setNPrompt('');
    setprompts({
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    });
    setnprompts({
      human_male: '',
      human_female: '',
      cat_male: '',
      cat_female: '',
      dog_male: '',
      dog_female: '',
    });
    setmultiParams({
      lorastrength: {
        human_male: '',
        human_female: '',
        cat_male: '',
        cat_female: '',
        dog_male: '',
        dog_female: '',
      },
      steps: {
        human_male: '',
        human_female: '',
        cat_male: '',
        cat_female: '',
        dog_male: '',
        dog_female: '',
      },
      cfg: {
        human_male: '',
        human_female: '',
        cat_male: '',
        cat_female: '',
        dog_male: '',
        dog_female: '',
      },
      refiner: {
        human_male: '',
        human_female: '',
        cat_male: '',
        cat_female: '',
        dog_male: '',
        dog_female: '',
      },
      refinersteps: {
        human_male: '',
        human_female: '',
        cat_male: '',
        cat_female: '',
        dog_male: '',
        dog_female: '',
      },
      sampler: {
        human_male: '',
        human_female: '',
        cat_male: '',
        cat_female: '',
        dog_male: '',
        dog_female: '',
      },
    });
    setloadedPromptName('');
    setLoadedPromptCategory('');
    setLoadedPromptGroup('');
    setpromptHistoryList([]);
    deletePromptLocally();
    setSelectedGender('male');
    setSelectedSpecies('human');
    setIsProduction('No');
    setseedsList([]);
    setLoraStrength(1);
    setPromptName('');
    setSelectedCategory('');
    setSelectedGroup('');
    setCheckpoint('1_sd_xl_base_1.0');
  };

  // UPDATE PROMPT
  const handleUpdatePrompt = () => {
    console.log('update prompt');
    const key = `${selectedSpecies}_${selectedGender}`;
    setprompts((current) => ({ ...current, [key]: promptRef.current.value }));
    setnprompts((current) => ({ ...current, [key]: nprompt }));

    const p = { ...prompts, [key]: promptRef.current.value };
    const np = { ...nprompts, [key]: nprompt };
    const params_temp = {
      lorastrength: { ...multiParams.lorastrength, [key]: loraStrength },
      steps: { ...multiParams.steps, [key]: steps },
      cfg: { ...multiParams.cfg, [key]: cfgValue },
      refiner: { ...multiParams.refiner, [key]: refiner },
      refinersteps: { ...multiParams.refinersteps, [key]: refinerSteps },
      sampler: { ...multiParams.sampler, [key]: sampler },
    };
    const newPrompt = {
      name: loadedPromptName,
      parent: loadedPromptGroup,
      category: loadedPromptCategory,
      modified_by: user,
      createdAt: getDateAndTime(),
      checkpointVersion: sdVersion,
      checkpoint: checkpoint,
      width: width,
      height: height,
      steps: steps,
      seed: seedsList,
      cfg: cfgValue,
      sampler: sampler,
      prompt: promptRef.current.value, //prompt,
      nprompt: nprompt,
      thumb: promptThumb,
      gender: selectedGender,
      species: selectedSpecies,
      isProduction: isProduction,
      loraStrength: loraStrength,
      prompts: p,
      nprompts: np,
      params: params_temp,
    };
    updatePrompt(newPrompt).then((resp) => {
      if (resp.success) {
        setAlert({
          show: true,
          severity: 'success',
          msg: 'Prompt saved',
        });
        updateHistory(promptId, promptHistoryList).then((resp) => {
          if (resp.success) {
            console.log('succes');
          } else {
            console.log(resp);
          }
        });
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  // MULTIPROMPT switch
  useEffect(() => {
    // change multi prompt values
    const key = `${selectedSpecies}_${selectedGender}`;
    promptRef.current.value = prompts[key] !== '' ? prompts[key] : '';
    setNPrompt(nprompts[key] !== '' ? nprompts[key] : '');
    setSteps(multiParams.steps[key] !== '' ? multiParams.steps[key] : 30);
    setCfgValue(multiParams.cfg[key] !== '' ? multiParams.cfg[key] : 7);
    setRefiner(
      multiParams.refiner[key] !== '' ? multiParams.refiner[key] : false
    );
    setrefinerSteps(
      multiParams.refinersteps[key] !== '' ? multiParams.refinersteps[key] : 25
    );
    setLoraStrength(
      multiParams.lorastrength[key] !== '' ? multiParams.lorastrength[key] : 1
    );
    setSampler(
      multiParams.sampler[key] !== '' ? multiParams.sampler[key] : 'Euler a'
    );
  }, [selectedGender, selectedSpecies]);

  ////////////////////////////////////////////////////////////////////

  return (
    <>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <Box
        sx={{
          alignSelf: 'flex-start',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          gap: 2,
        }}
      >
        {loadedPromptName !== '' && (
          <Paper
            sx={{
              alignSelf: 'start',
              mb: '10px',
              padding: '5px 10px',
              backgroundColor: '#009fff',
            }}
          >
            <Typography
              sx={{ color: '#292929', fontSize: '14px', fontWeight: '600' }}
            >
              {loadedPromptCategory +
                ' > ' +
                loadedPromptGroup +
                ' > ' +
                loadedPromptName}
            </Typography>
          </Paper>
        )}
        <Paper
          sx={{
            alignSelf: 'start',
            mb: '10px',
            padding: '5px 10px',
            backgroundColor: '#009fff',
          }}
        >
          <Typography
            sx={{ color: '#292929', fontSize: '14px', fontWeight: '600' }}
          >{`Selected LORAs: ${
            selectedLorasCount.male !== 0
              ? '  Male: ' + selectedLorasCount.male
              : ''
          } ${
            selectedLorasCount.female !== 0
              ? '  Female: ' + selectedLorasCount.female
              : ''
          } ${
            selectedLorasCount.cat !== 0
              ? '  Cat: ' + selectedLorasCount.cat
              : ''
          } ${
            selectedLorasCount.dog !== 0
              ? '  Dog: ' + selectedLorasCount.dog
              : ''
          }`}</Typography>
        </Paper>
        {quickLoraSelect && (
          <Paper
            sx={{
              alignSelf: 'start',
              mb: '10px',
              padding: '5px 10px',
              backgroundColor: '#009fff',
            }}
          >
            <Typography
              sx={{ color: '#292929', fontSize: '14px', fontWeight: '600' }}
            >{`LORAs strength: ${loraStrength}`}</Typography>
          </Paper>
        )}
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{
          mt: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            alignSelf: 'flex-start',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <SimpleAccordion title="Checkpoint settings" expanded={true}>
            <>
              {/* <Box
                sx={{
                  mt: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {/*<div>SD version</div>
                 <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={versionSwitch}
                        onChange={(event) =>
                          setVersionSwitch(event.target.checked)
                        }
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={sdVersion}
                  />
                </FormGroup> 
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={license}
                        onChange={(event) => setLicense(event.target.checked)}
                      />
                    }
                    label="Show checkpoints with shady license"
                  />
                </FormGroup>
              </Box> */}

              <Box
                sx={{
                  mt: 1,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                <FormControl fullWidth className="ring-0">
                  <InputLabel id="demo-simple-select-label">
                    checkpoint
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={checkpoint}
                    label="Checkpoint"
                    onChange={(event) => setCheckpoint(event.target.value)}
                  >
                    {checkpoints.map((model, index) => {
                      return (
                        <MenuItem key={index} value={model.model_name}>
                          {model.model_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <Button variant="contained" onClick={handleLoraOpen}>
                  LORAs
                </Button>

                <LoraDialog
                  lorasList={lorasList.map((lora) => ({
                    ...lora,
                    selected: selectedLoras.some((l) => l.name === lora.name)
                      ? true
                      : false,
                    strength: loraStrength,
                  }))}
                  open={loraDialogOpen}
                  onClose={handleLoraClose}
                  setLoraStrength={setLoraStrength}
                  loraStrength={loraStrength}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  paddingTop: '20px',
                  justifyContent: 'center',
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={quickLoraSelect}
                        onChange={(event) =>
                          setquickLoraSelect(event.target.checked)
                        }
                      />
                    }
                    label="Quick lora select"
                  />
                </FormGroup>
              </Box>
            </>
          </SimpleAccordion>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
            <SimpleAccordion title="Prompt curation" expanded={true}>
              <Box
                sx={{
                  mt: 1,
                  width: 'auto',

                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'start',
                  gap: '5px',
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRevertPrompt}
                >
                  New
                </Button>
                {loadedPromptName !== '' && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpdatePrompt}
                    disabled={promptAuthor !== user && !isAdmin}
                  >
                    Save
                  </Button>
                )}
                {/* SAVE PROMPT DIALOG */}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setSaveDialogOpen(true)}
                >
                  Save as
                </Button>
                <SaveAs
                  open={saveDialogOpen}
                  setOpen={setSaveDialogOpen}
                  setSaveDialogData={setSaveDialogData}
                  savePromptBlocked={savePromptBlocked}
                  promptName={promptName}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  selectedGroup={selectedGroup}
                  setSelectedGroup={setSelectedGroup}
                  setPromptName={setPromptName}
                  setNewGroupName={setNewGroupName}
                />

                <Button
                  variant="blue"
                  color="primary"
                  onClick={() => setPromptListDialogOpen(true)}
                >
                  OPEN LIST
                </Button>
                <PromptsList
                  open={promptListDialogOpen}
                  setOpen={setPromptListDialogOpen}
                  setPromptToLoad={setPromptToLoad}
                />
                {/* <FullScreenDialog
                  open={promptListDialogOpen}
                  setOpen={setPromptListDialogOpen}
                  setPromptToLoad={setPromptToLoad}
                /> */}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '20px',
                  marginTop: '23px',
                }}
              >
                <Dropdown
                  name="Species"
                  value={selectedSpecies}
                  setValue={handleSetSpecies}
                  items={speciesValues}
                />
                <Dropdown
                  name="Gender"
                  value={selectedGender}
                  setValue={handleSetGender}
                  items={genderValues}
                />
                <Dropdown
                  name="Production"
                  value={isProduction}
                  setValue={setIsProduction}
                  items={productionValues}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setseedDialogOpen(true)}
                >
                  Seeds
                </Button>
                {/* SEEDS DIALOG */}
                <SmallDialog
                  title="Seeds"
                  content=""
                  open={seedDialogOpen}
                  setOpen={setseedDialogOpen}
                  setData={setseedDialogData}
                  cancelButton="Close"
                  confirmButton=""
                >
                  <Box
                    sx={{
                      mt: 1,
                      width: '500px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '20px',
                    }}
                  >
                    <List sx={{ width: '100%' }}>
                      {seedsList.length !== 0 &&
                        seedsList.map((seed, idx) => {
                          return (
                            <>
                              <ListItem
                                key={idx}
                                disableGutters
                                sx={{
                                  backgroundColor:
                                    idx % 2 ? '#515151' : '#6b6b6b',
                                  mb: '10px',
                                  padding: '10px 20px',
                                }}
                                secondaryAction={
                                  <>
                                    <IconButton
                                      variant="contained"
                                      size="small"
                                      sx={{ mr: '20px', color: '#fff' }}
                                      onClick={() => setseed(seed)}
                                    >
                                      <RecyclingIcon></RecyclingIcon>
                                    </IconButton>
                                    <IconButton
                                      variant="contained"
                                      size="small"
                                      sx={{ mr: '20px', color: '#fff' }}
                                      onClick={() =>
                                        setseedsList((current) =>
                                          current.filter((s) => s !== seed)
                                        )
                                      }
                                    >
                                      <DeleteIcon></DeleteIcon>
                                    </IconButton>
                                  </>
                                }
                              >
                                {seed}
                              </ListItem>
                            </>
                          );
                        })}
                    </List>
                  </Box>
                </SmallDialog>
              </Box>
            </SimpleAccordion>
          </Box>
          {isAdmin && (
            <ProductGenerate
              selectedLoras={selectedLoras}
              checkpoint={checkpoint}
              gender={selectedGender}
              species={selectedSpecies}
              setisLoading={setisLoading}
              setResultImage={setResultImage}
              setResultSeeds={setResultSeeds}
              setStarted={setStarted}
              settotalJobs={settotalJobs}
              setproductGeneration={setproductGeneration}
              setBatchStatus={setbatchStatus}
              setproductsCount={setproductsCount}
              setLoraThumbsList={setLoraThumbsList}
            />
          )}
        </Box>
        <Box
          sx={{
            mt: 1,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <TextField
            margin="normal"
            id="width"
            label="width"
            name="width"
            value={width}
            onChange={(event) => setWidth(event.target.value)}
            className="ring-0"
            sx={{ width: '100px' }}
            size="small"
          />
          <TextField
            margin="normal"
            id="height"
            label="height"
            name="height"
            value={height}
            onChange={(event) => setHeight(event.target.value)}
            className="ring-0"
            sx={{ width: '100px' }}
            size="small"
          />
          {/* <TextField
            margin="normal"
            id="batch"
            label="batch"
            name="batch"
            value={batchSize}
            onChange={(event) =>
              setbatchSize(
                hires
                  ? 1
                  : parseInt(event.target.value) > 5
                  ? 5
                  : event.target.value
              )
            }
            className="ring-0"
            sx={{ width: '100px' }}
            size="small"
          /> */}

          {/* TODO */}
          <TextField
            margin="normal"
            id="batch"
            label="batch"
            name="batch"
            value={batchSize}
            onChange={(event) =>
              setbatchSize(
                hires
                  ? 1
                  : selectedLoras.length === 1
                  ? parseInt(event.target.value)
                  : parseInt(event.target.value) > 5
                  ? 5
                  : event.target.value
              )
            }
            className="ring-0"
            sx={{ width: '100px' }}
            size="small"
          />

          <TextField
            margin="normal"
            id="steps"
            label="steps"
            name="steps"
            autoComplete="steps"
            value={steps}
            onChange={(event) => setSteps(event.target.value)}
            className="ring-0"
            sx={{ width: '100px' }}
            size="small"
          />
          <TextField
            margin="normal"
            id="seed"
            label="seed"
            name="seed"
            autoComplete="seed"
            value={seed}
            onChange={handleSeedChange}
            className="ring-0"
            sx={{ width: '130px' }}
            size="small"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: '5px',
              ml: '-5px',
            }}
          >
            <IconButton size="small" onClick={handleRandomSeed}>
              <CasinoIcon fontSize="inherit" color="primary" />
            </IconButton>
            {/* <IconButton size="small" onClick={handleReuseSeed}>
              <RecyclingIcon fontSize="inherit" color="primary" />
            </IconButton> */}
          </Box>
          <FormControl
            className="ring-0"
            size="small"
            sx={{ mt: '7px', width: '150px' }}
          >
            <InputLabel id="demo-simple-select-label">sampler</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sampler}
              label="sampler"
              onChange={(event) => setSampler(event.target.value)}
            >
              {samplers.map((sampler, index) => {
                return (
                  <MenuItem key={index} value={sampler}>
                    {sampler}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <StepedSlider
            label="CFG scale"
            value={cfgValue}
            setValue={setCfgValue}
            step={0.5}
            min={1}
            max={30}
            width={300}
            marks={true}
          />
          <FormGroup>
            <FormControlLabel
              sx={{ marginTop: '5px', marginLeft: '10px', color: '#fff' }}
              control={
                <Checkbox
                  checked={refiner}
                  onChange={(event) => handleSetRefiner(event.target.checked)}
                />
              }
              label="Refiner"
            />
          </FormGroup>
          {refiner && (
            <TextField
              margin="normal"
              id="refinerSteps"
              label="steps"
              name="refinerSteps"
              value={refinerSteps}
              onChange={(event) => handleSetRefinerSteps(event.target.value)}
              className="ring-0"
              sx={{ width: '100px' }}
              size="small"
              type="number"
            />
          )}
          <FormGroup>
            <FormControlLabel
              sx={{ marginTop: '5px', marginLeft: '10px', color: '#fff' }}
              control={
                <Checkbox
                  checked={hires}
                  onChange={(event) => handleSetHires(event.target.checked)}
                />
              }
              label="Hires"
            />
          </FormGroup>
          {hires && (
            <TextField
              margin="normal"
              id="upscaleBy"
              label="upscale by"
              name="upscaleBy"
              value={upscaleBy}
              onChange={(event) => setUpscaleBy(event.target.value)}
              className="ring-0"
              sx={{ width: '100px' }}
              size="small"
            />
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '3px',
          }}
        >
          {/* <TextField
            margin="normal"
            required
            fullWidth
            id="prompt"
            label="Prompt"
            name="prompt"
            autoComplete="prompt"
            value={prompt}
            onChange={(event) => setPrompt(event.target.value)}
            multiline={true}
            autoFocus
            className="ring-0"
          /> */}
          <TextField
            margin="normal"
            required
            fullWidth
            id="prompt"
            label="Prompt"
            name="prompt"
            autoComplete="prompt"
            inputRef={promptRef}
            multiline={true}
            autoFocus
            className="ring-0"
          />

          <PromptHistoryDialog
            setPrompt={promptRef}
            promptHistoryList={promptHistoryList}
          ></PromptHistoryDialog>
        </Box>
        <TextField
          margin="normal"
          required
          fullWidth
          id="nprompt"
          label="Negative prompt"
          name="nprompt"
          multiline={true}
          value={nprompt}
          onChange={(event) => setNPrompt(event.target.value)}
          autoComplete="prompt"
          autoFocus
          className="ring-0"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2, width: '200px' }}
          disabled={
            selectedLoras.length !== 0
              ? checkpoint === ''
                ? true
                : false
              : true
          }
        >
          {' '}
          GENERATE
        </Button>
      </Box>
      {isLoading ? (
        !productGeneration ? (
          <Box sx={{ width: '100%' }}>
            {selectedLoras.length === 1 ? (
              <Typography sx={{ color: '#fff' }}>
                {`Generating job ` +
                  batchStatus +
                  ' of ' +
                  Math.ceil(batchSize / 5)}
              </Typography>
            ) : (
              <Typography sx={{ color: '#fff' }}>
                {'Generating lora ' +
                  batchStatus +
                  ' of ' +
                  selectedLoras.length}
              </Typography>
            )}

            {/* <LinearProgressWithLabel value={progress} /> */}
            <LinearProgress />
          </Box>
        ) : (
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ color: '#fff' }}>
              {'Generating product ' + batchStatus + ' of ' + productsCount}
            </Typography>
            <LinearProgress />
          </Box>
        )
      ) : (
        <></>
      )}
      {resultImage &&
        //   <Box sx={{ width: '100%' }}>
        //     <Gallery
        //       images={resultImage}
        //       seeds={resultSeeds}
        //       setSeed={setseed}
        //       setPromptThumb={setPromptThumb}
        //     />
        //   </Box>
        // )
        (!productGeneration ? (
          selectedLoras.length === 1 ? (
            batchSize <= 5 ? (
              <Box sx={{ width: '100%' }}>
                <Gallery
                  images={resultImage}
                  seeds={resultSeeds}
                  setSeed={setseed}
                  setPromptThumb={setPromptThumb}
                  addToSeedList={addToSeedList}
                  navigation={false}
                  promptName={loadedPromptName}
                  productName={loadedPromptGroup}
                />
              </Box>
            ) : (
              new Array(5).fill().map((v, i) => {
                return (
                  <Box sx={{ width: '100%' }}>
                    <Gallery
                      images={resultImage.slice(i * 6, (i + 1) * 6)}
                      seeds={resultSeeds.slice(i * 6, (i + 1) * 6)}
                      setSeed={setseed}
                      setPromptThumb={setPromptThumb}
                      addToSeedList={addToSeedList}
                      navigation={false}
                      promptName={loadedPromptName}
                      productName={loadedPromptGroup}
                    />
                  </Box>
                );
              })
            )
          ) : (
            selectedLoras.map((lora, idx) => {
              return (
                <Box sx={{ width: '100%' }}>
                  <Gallery
                    images={resultImage.slice(
                      idx * (parseInt(batchSize) + 1),
                      (idx + 1) * (parseInt(batchSize) + 1)
                    )}
                    seeds={resultSeeds.slice(
                      idx * (parseInt(batchSize) + 1),
                      (idx + 1) * (parseInt(batchSize) + 1)
                    )}
                    setSeed={setseed}
                    setPromptThumb={setPromptThumb}
                    addToSeedList={addToSeedList}
                    navigation={false}
                    promptName={loadedPromptName}
                    productName={loadedPromptGroup}
                  />
                </Box>
              );
            })
          )
        ) : (
          selectedLoras.map((lora, idx) => {
            return (
              <Box sx={{ width: '100%' }}>
                <Gallery
                  images={resultImage}
                  seeds={resultSeeds}
                  setSeed={setseed}
                  setPromptThumb={setPromptThumb}
                  addToSeedList={addToSeedList}
                  navigation={true}
                  promptName={loadedPromptName}
                  productName={loadedPromptGroup}
                />
              </Box>
            );
          })
        ))}
    </>
  );
};

export default Generate;

// resultImage.map((img, index) => {
//   return (
//     <img
//       src={`data:image/jpeg;base64,${img}`}
//       alt=""
//       width="300"
//       key={index}
//     />
//   );
// })
