/* eslint-disable no-unused-vars */
import { useState, useMemo } from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Toast from './Toast';
import { useAuth } from '../utils/Auth';
import { useNavigate } from 'react-router-dom';

export default function Signup({ setIsLogin }) {
  const navigate = useNavigate();
  const { signup } = useAuth();

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const [form, setForm] = useState({
    email: '',
    password: '',
    cpassword: '',
    invite: '',
  });
  const [errorMsg, setErrorMsgs] = useState({
    email: '',
    password: '',
    cpassword: '',
    invite: '',
  });
  const [error, setError] = useState(false);

  const validate = () => {
    let temp = {};
    temp.email = form.email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      ? ''
      : 'email is not valid';
    temp.password =
      form.password.length < 6
        ? 'Password need to be at least 6 characters long'
        : '';
    temp.cpassword =
      form.cpassword === form.password ? '' : "Passwords doesn't match";
    temp.invite = form.invite.length < 8 ? 'Invite code is too short' : '';
    setErrorMsgs(temp);
    console.log(Object.values(temp).every((value) => value == ''));
    return Object.values(temp).every((value) => value == '');
  };

  const onChange = (i) => {
    setForm({ ...form, [i.target.name]: i.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validate()) {
      setError(true);
      return false;
    }
    const formData = new FormData(event.currentTarget);
    const data = {
      email: formData.get('email'),
      password: formData.get('password'),
      invite: formData.get('invite'),
    };
    data.isAdmin = false;

    signup(data)
      .then((resp) => {
        if (resp.success) {
          setAlert({
            show: true,
            severity: 'success',
            msg: 'Account created',
          });
          setTimeout(() => {
            setIsLogin(true);
          }, 2000);
        } else {
          setAlert({
            show: true,
            severity: 'error',
            msg: resp.msg,
          });
        }
      })
      .catch((err) => {
        setAlert({
          show: true,
          severity: 'error',
          msg: err,
        });
      });
  };

  return (
    <>
      <Toast alert={alert} setAlert={setAlert} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" sx={{ color: '#fff' }}>
            Sign up
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={onChange}
                  {...(error && {
                    error: errorMsg['email'].length > 0 ? true : false,
                    helperText: errorMsg['email'],
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={onChange}
                  {...(error && {
                    error: errorMsg['password'].length > 0 ? true : false,
                    helperText: errorMsg['password'],
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="cpassword"
                  label="Confirm password"
                  type="password"
                  id="cpassword"
                  onChange={onChange}
                  {...(error && {
                    error: errorMsg['cpassword'].length > 0 ? true : false,
                    helperText: errorMsg['cpassword'],
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="invite"
                  label="Invite code"
                  name="invite"
                  autoComplete="invite"
                  onChange={onChange}
                  {...(error && {
                    error: errorMsg['invite'].length > 0 ? true : false,
                    helperText: errorMsg['invite'],
                  })}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <div onClick={() => setIsLogin(true)} className="link ">
                  Already have an account? Sign in
                </div>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  );
}
