import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as Sentry from '@sentry/react';
import App from './App';

import { AuthProvider } from './utils/Auth';
import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from './MUItheme';
import { CmsProvider } from './utils/CMS';

Sentry.init({
  dsn: 'https://da6274f7f2afed4401788e563315d8f8@o4505313361526784.ingest.sentry.io/4505731035561984',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', 'https://rest-as.garagefarm.net'],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <AuthProvider>
        <CmsProvider>
          <App />
        </CmsProvider>
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
