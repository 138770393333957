import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../utils/Auth';

import CircularProgress from '@mui/material/CircularProgress';

const GuardedRoute = ({ children }) => {
  const { currentUser, isLoading } = useAuth();
  const [user, setuser] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      console.log(`currentUser: ${currentUser}`);
    }
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <div className="center">
          <CircularProgress />
        </div>
      ) : currentUser ? (
        children
      ) : (
        <Navigate to="/" replace />
      )}
    </>
  );
};

export default GuardedRoute;
