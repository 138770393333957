import { useState, useEffect } from 'react';

import { useAuth } from '../../utils/Auth';
import './Dashboard.css';

import { Box, Button, IconButton } from '@mui/material';
import Generate from './Generate';
import Training from './Training';
import Reports from './Reports/Reports';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Toast from '../Toast';

import useSse from '../../hooks/useSse';
import Users from './Users/Users';

const Dashboard = () => {
  const APP_VERSION = '2.2.1';
  const { getCurrentUser, generate, getProgress, status, username } = useAuth();

  // const { data, setStarted, settotalJobs } = useSse(username.split('@')[0]);

  const [user, setuser] = useState({ email: '', admin: false, id: '' });
  const [menuItems, setMenuItems] = useState({
    currentActive: 'Generate',
    items: [],
  });
  const [sidebarHidden, setsidebarHidden] = useState(true);

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  // useEffect(() => {
  //   if (data && data.message.type === 'training') {
  //     console.log(data.message.type);
  //     setAlert({
  //       show: true,
  //       severity: 'success',
  //       msg: 'Training completed',
  //     });
  //   } else if (data && data.type === 'generate') {
  //     console.log(data.message.type);
  //     setAlert({
  //       show: true,
  //       severity: 'success',
  //       msg: 'Generation completed',
  //     });
  //   }
  // }, [data]);

  useEffect(() => {
    const fetchUser = async () => {
      let us = await getCurrentUser();
      setuser(us);
    };
    fetchUser();
    // username !== '' && setuser(username);
  }, []);

  useEffect(() => {
    if (user.email !== '') {
      if (user.admin) {
        setMenuItems((current) => ({
          ...current,
          items: [
            {
              name: 'Generate',
              isActive: true,
              admin: false,
              component: <Generate user={user.email} />,
            },
            {
              name: 'Training',
              isActive: false,
              admin: false,
              component: <Training />,
            },
            {
              name: 'Users',
              isActive: false,
              admin: true,
              component: <Users />,
            },
            {
              name: 'Reports',
              isActive: false,
              admin: true,
              component: <Reports />,
            },
          ],
        }));
      } else {
        setMenuItems((current) => ({
          ...current,
          items: [
            {
              name: 'Generate',
              isActive: true,
              admin: false,
              component: <Generate user={user.email} />,
            },
            {
              name: 'Training',
              isActive: false,
              admin: false,
              component: <Training />,
            },
          ],
        }));
      }
    }
  }, [user]);

  const handleMenuChange = (item) => {
    console.log(item);
    const dummy = { ...menuItems };
    dummy.currentActive = item.name;
    dummy.items.forEach((i) => {
      if (i.name === item.name) {
        i.isActive = true;
      } else {
        i.isActive = false;
      }
    });
    setMenuItems(dummy);
  };

  const handleSidebar = () => {
    setsidebarHidden(!sidebarHidden);
  };
  return (
    <>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <div className="dashboard-container">
        <div
          className={
            sidebarHidden ? 'sidebar-container hidden' : 'sidebar-container'
          }
        >
          <div className="top">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: '#fff',
                fontSize: '12px',
              }}
            >
              <img src="/logo192.png" width="64"></img>
              <p>{APP_VERSION}</p>
            </Box>

            <div className="username"> {user.email}</div>
          </div>
          <div className="menu">
            {menuItems.items.map((item, index) => {
              return (
                <Button
                  variant={item.isActive ? 'menuActive' : 'menu'}
                  key={index}
                  onClick={() => handleMenuChange(item)}
                >
                  {item.name}
                </Button>
              );
            })}
          </div>
        </div>

        <div
          className={
            sidebarHidden ? 'content-container full' : 'content-container'
          }
        >
          <IconButton
            onClick={handleSidebar}
            color="primary"
            sx={{
              position: 'relative',
              top: '50%',
              left: '-51%',
              ml: '-20px',
              color: '#fff',
            }}
          >
            {sidebarHidden ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}
          </IconButton>

          {menuItems.items.map((item, index) => {
            if (item.isActive) {
              return item.component;
            }
          })}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
