import { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  ListItemText,
} from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

const DropdownChecks = ({ name, setValue, items, width }) => {
  const [localvalue, setlocalvalue] = useState([]);

  useEffect(() => {
    items &&
      setlocalvalue(
        items.filter((item) => item !== 'test bin')
        //.map((item) => item.split(' - ')[0])
      );
  }, [items]);

  const handleSelect = (valuex) => {
    setlocalvalue(typeof valuex === 'string' ? valuex.split(',') : valuex);
  };
  return (
    <FormControl className="ring-0">
      <InputLabel id="demo-simple-select-label">{name}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={name}
        multiple
        value={localvalue}
        label={name}
        onChange={(e) => handleSelect(e.target.value)}
        onClose={() => setValue(localvalue)}
        renderValue={(selected) =>
          selected.map((item) => item.split(' - ')[0]).join(', ')
        }
        size="small"
        sx={{ mt: '5px', width: width !== undefined ? width : '120px' }}
      >
        {items.map((item) => {
          return (
            <MenuItem value={item}>
              <Checkbox checked={localvalue.indexOf(item) > -1} />
              <ListItemText primary={item.split(' - ')[0]} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DropdownChecks;
