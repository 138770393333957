import { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';

import { IconButton, TextField } from '@mui/material';

import { useCms } from '../../../utils/CMS';
import SmallDialog from '../SmallDialog';
import Toast from '../../Toast';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 80,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(255, 255, 255)'
        : 'rgb(255, 255, 255)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: 'rgb(255, 255, 255)',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CategoryMenu({ name, index, updateName, addProduct }) {
  const { updateCategory, removeCategory, getTree, addSubcategory } = useCms();
  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const [renameCategoryOpen, setRenameCategoryOpen] = useState(false);
  const [renameCategoryData, setRenameCategoryData] = useState(false);
  const [categoryName, setCategoryName] = useState(name);

  const [addSubcategoryOpen, setAddSubcategoryOpen] = useState(false);
  const [addSubcategoryData, setAddSubcategoryData] = useState(false);
  const [subcategoryName, setSubcategoryName] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setTree = '';
  // CATEGORIES ADD
  useEffect(() => {
    if (renameCategoryData) {
      handleClose();
      updateCategory(name, categoryName).then((resp) => {
        if (resp.success) {
          updateName(categoryName);
          setRenameCategoryData(false);
        } else {
          setAlert({
            show: true,
            severity: 'error',
            msg: resp.msg,
          });
        }
      });
    }
  }, [renameCategoryData]);

  // SUBCATEGORIES ADD
  useEffect(() => {
    if (addSubcategoryData) {
      handleClose();
      let group = {
        name: subcategoryName,
        production: false,
        parent: name,
        prompts: [],
        price: 0,
      };
      addSubcategory(group).then((resp) => {
        if (resp.success) {
          addProduct(group);
          setAddSubcategoryData(false);
        } else {
          setAlert({
            show: true,
            severity: 'error',
            msg: resp.msg,
          });
        }
      });
    }
  }, [addSubcategoryData]);

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };
  const handleSubcategoryNameChange = (event) => {
    setSubcategoryName(event.target.value);
  };

  const handleRemoveCategory = () => {
    removeCategory(name).then((resp) => {
      if (resp.success) {
        getTree().then((tree) => {
          setTree(tree);
        });
      }
    });
  };

  return (
    <div>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <IconButton aria-label="open" onClick={handleClick} variant="contained">
        <MenuOpenIcon fontSize="inherit" sx={{ color: '#fff' }} />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setAddSubcategoryOpen(true)} disableRipple>
          <AddIcon />
          Add product
        </MenuItem>
        <SmallDialog
          title="Add product"
          content=""
          open={addSubcategoryOpen}
          setOpen={setAddSubcategoryOpen}
          setData={setAddSubcategoryData}
          cancelButton="Cancel"
          confirmButton="Add"
          blocked={false}
        >
          <TextField
            margin="normal"
            id="productName"
            label="name"
            name="name"
            value={subcategoryName}
            onChange={handleSubcategoryNameChange}
            onKeyDown={(e) => e.stopPropagation()}
            className="ring-0"
            sx={{ width: '300px' }}
            size="small"
          />
        </SmallDialog>
        <MenuItem onClick={() => setRenameCategoryOpen(true)} disableRipple>
          <CreateIcon />
          Rename category
        </MenuItem>
        <SmallDialog
          title="Rename category"
          content=""
          open={renameCategoryOpen}
          setOpen={setRenameCategoryOpen}
          setData={setRenameCategoryData}
          cancelButton="Cancel"
          confirmButton="Rename"
          blocked={false}
        >
          <TextField
            margin="normal"
            id="promptName"
            label="name"
            name="name"
            value={categoryName}
            onChange={handleCategoryNameChange}
            className="ring-0"
            sx={{ width: '300px' }}
            size="small"
          />
        </SmallDialog>
        {/* <MenuItem onClick={handleRemoveCategory} disableRipple>
          <DeleteIcon />
          Delete category
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
}
