import { Button } from '@mui/material';
import { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';

const Camera = ({ setImages }) => {
  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 1024,
    height: 1024,
    facingMode: 'user',
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot({
      width: 1024,
      height: 1024,
    });
    console.log(imageSrc);
    setImages((images) => [...images, imageSrc]);
  }, [webcamRef]);
  return (
    <>
      <div className="camera-container">
        <div className="overlay"></div>
        <Webcam
          height={400}
          width={400}
          ref={webcamRef}
          mirrored={true}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
        />
        <Button variant="menu-active" onClick={capture}>
          Capture
        </Button>
      </div>
    </>
  );
};

export default Camera;
