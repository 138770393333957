import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';

import { useCms } from '../../../utils/CMS';
import ContextMenu from './ContextMenu';
import { Progress, TextWrapCellRenderer } from '../SmallComponents';
import Toast from '../../Toast';

function formatDate(inputString) {
  // Parse the input string into a Date object
  const dateObj = new Date(inputString);

  // Format the date components
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

const columns = [
  {
    field: '_id',
    headerName: '_id',
    width: 230,
    type: 'string',
  },
  {
    field: 'email',
    headerName: 'email',
    width: 280,
    type: 'string',
  },

  {
    field: 'created_at',
    headerName: 'created at',
    width: 170,
    editable: false,
  },
  {
    field: 'activated',
    headerName: 'activated',
    width: 160,
    type: 'boolean',
  },
  {
    field: 'admin',
    headerName: 'admin',
    width: 160,
    editable: false,
    type: 'boolean',
  },
  {
    field: 'balance',
    headerName: 'balance',
    width: 150,
    editable: false,
  },
];

export default function DataGridUsers() {
  const apiRef = useGridApiRef();

  const { getUsers, addCredits, activateAccount, changeAdminStatus } = useCms();
  const [rows, setrows] = useState([]);
  const [loading, setloading] = useState(true);

  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  useEffect(() => {
    async function gr() {
      const users = await getUsers();
      const u = users.users.map((r, index) => {
        var temp = { ...r };
        temp.balance = r?.payments.balance;
        temp.id = index;
        temp.created_at = formatDate(r.date);
        return temp;
      });
      setrows(u);
    }
    if (rows.length === 0) {
      gr();
    }
  }, []);

  useEffect(() => {
    rows.length !== 0 && setloading(false);
  }, [rows]);

  const getRowHeight = (params) => {
    const lineHeight = 20; // Adjust this value based on your font size and styling
    const lines = Math.ceil(params.model.created_at.length / 50); // Adjust the number of characters per line as needed
    var h = lines * lineHeight + 16;
    if (h < 100) {
      return 100;
    } else {
      return h;
    }
  };

  const handleOnCellClick = (params) => {
    if (params.field === 'image') {
      window.open(params.value, '_blank', 'noreferrer');
    }
  };

  const [contextMenu, setContextMenu] = useState(null);
  const [rowClicked, setrowClicked] = useState(null);
  const handleRowContextMenu = (event) => {
    event.preventDefault();
    const rowId = Number(event.currentTarget.getAttribute('data-id'));
    setrowClicked(rowId);

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  // const markAsCompleted = () => {
  //   const record = rows.find((row) => row.id === rowClicked);
  //   console.log(apiRef.current.getRow(rowClicked));
  //   const r = apiRef.current.getRow(rowClicked);
  //   if (!r.reviewed) {
  //     updateReport({ q: { uuid: record.uuid }, s: { reviewed: true } }).then(
  //       (resp) => {
  //         if (resp.status === 'success') {
  //           apiRef.current.updateRows([{ id: rowClicked, reviewed: true }]);
  //         }
  //       }
  //     );
  //   } else {
  //     updateReport({ q: { uuid: record.uuid }, s: { reviewed: false } }).then(
  //       (resp) => {
  //         if (resp.status === 'success') {
  //           apiRef.current.updateRows([{ id: rowClicked, reviewed: false }]);
  //         }
  //       }
  //     );
  //   }
  // };

  const copyPath = (generate) => {
    const record = apiRef.current.getRow(rowClicked);

    const path = `u:\\a0000009\\Gleem\\${generate ? 'Generation' : 'LORAs'}\\${
      record.email.split('@')[0]
    }`;
    navigator.clipboard.writeText(path);
  };

  const handleTopUp = (value) => {
    const r = apiRef.current.getRow(rowClicked);
    addCredits(r.email, value).then((resp) => {
      if (resp.success) {
        apiRef.current.updateRows([
          { id: rowClicked, balance: parseInt(r.balance) + value },
        ]);
        setAlert({
          show: true,
          severity: 'success',
          msg: `succesfully added credits for email ${r.email}.`,
        });
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: 'Error adding credits',
        });
      }
    });
  };

  const handleActivateAccount = () => {
    const r = apiRef.current.getRow(rowClicked);
    activateAccount(r.email).then((resp) => {
      if (resp.success) {
        apiRef.current.updateRows([{ id: rowClicked, activated: true }]);
        setAlert({
          show: true,
          severity: 'success',
          msg: ` ${r.email} account activated`,
        });
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: 'Error activating account',
        });
      }
    });
  };

  const handleChangeAdminStatus = () => {
    const r = apiRef.current.getRow(rowClicked);
    changeAdminStatus(r.email, !r.admin).then((resp) => {
      if (resp.success) {
        apiRef.current.updateRows([{ id: rowClicked, admin: !r.admin }]);
        setAlert({
          show: true,
          severity: 'success',
          msg: ` ${r.email} account ${
            r.admin ? 'is no longer admin' : 'is now admin'
          } `,
        });
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: 'Error c',
        });
      }
    });
  };

  return (
    <>
      <Toast alert={alert} setAlert={setAlert} />
      <Box sx={{ height: '80vh', width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            row: {
              onContextMenu: handleRowContextMenu,
              style: { cursor: 'context-menu' },
            },
            loadingOverlay: Progress,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
            filter: {
              filterModel: {
                items: [],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            columns: {
              columnVisibilityModel: {
                index: false,
                job: false,
                order: false,
                generated_at: false,
              },
            },
          }}
          pageSizeOptions={[50]}
          onCellClick={handleOnCellClick}
          loading={loading}
        />
      </Box>
      <ContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        copyPath={copyPath}
        handleTopUp={handleTopUp}
        handleActivateAccount={handleActivateAccount}
        handleChangeAdminStatus={handleChangeAdminStatus}
      />
    </>
  );
}
