import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CategoryMenu from './CategoryMenu';
import GroupMenu from './GroupMenu';

function Row(props) {
  const { row, cat_index, setRows, cats, handleMoveProduct } = props;
  const [open, setOpen] = useState(false);
  const [rowx, setrowx] = useState(row);

  useEffect(() => {
    row && setrowx(row);
  }, [row]);

  const handleNameUpdate = (value) => {
    let temp = { ...rowx };
    temp['name'] = value;
    setrowx(temp);
  };

  const handleAddProduct = (product) => {
    let temp = { ...rowx };
    temp['productsCount'] = temp['productsCount'] + 1;
    temp.products.push(product);
    setrowx(temp);
  };

  const handleUpdateProduct = (key, value, index) => {
    let tempRow = { ...rowx };
    let tempProducts = [...rowx.products];
    if (key === 'delete') {
      tempProducts.splice(index, 1);
      tempRow.products = tempProducts;
      setrowx(tempRow);
    } else {
      tempProducts[index][key] = value;
      tempRow.products = tempProducts;
      setrowx(tempRow);
    }
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography>{rowx.name}</Typography>
        </TableCell>
        <TableCell align="right">
          <Typography>{rowx.productsCount}</Typography>
        </TableCell>
        <TableCell align="right">
          <CategoryMenu
            name={rowx.name}
            updateName={handleNameUpdate}
            addProduct={handleAddProduct}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Products{' '}
                <p style={{ color: '#ff6000', fontSize: '13px' }}>
                  {rowx.name}
                </p>
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#131313' }}>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Price ($)</TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowx.products.map((product, index) => (
                    <TableRow key={product.name}>
                      <TableCell component="th" scope="row" align="left">
                        <Typography>{product.name}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        {product.production ? (
                          <Typography sx={{ color: '#6a9c55' }}>
                            released
                          </Typography>
                        ) : (
                          <Typography sx={{ color: '#fc0000' }}>
                            not released
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {'$' + product.price}{' '}
                      </TableCell>
                      <TableCell align="center">
                        <GroupMenu
                          group={product}
                          index={index}
                          cat_index={cat_index}
                          update={handleUpdateProduct}
                          add={handleAddProduct}
                          cats={cats}
                          handleMoveProduct={(product, index) =>
                            handleMoveProduct(product, index)
                          }
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function CategoryManagerTable({ rows }) {
  const [rowsx, setrowsx] = useState(rows);
  const [catsNames, setcatsNames] = useState();
  useEffect(() => {
    if (rows) {
      setrowsx(rows);
      setcatsNames(rows.map((row) => row.name));
      console.log(rows);
    }
  }, [rows]);

  const handleMoveProduct = (product, index) => {
    console.log(`moving ${product.name} to category index ${index}`);
    let temp = [...rows];
    var temp2 = temp.map((category) => {
      if (product.parent === category.name) {
        category['productsCount'] = category['productsCount'] + 1;
        category.products.push(product);
      }

      return category;
    });
    // temp[index ]['productsCount'] = temp[index - 1]['productsCount'] + 1;
    // temp[index - 1].products.push(product);
    setrowsx(temp2);
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align="right">Products</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsx.map((row, index) => (
            <Row
              key={row.name}
              row={row}
              cat_index={index}
              setRows={setrowsx}
              cats={catsNames}
              handleMoveProduct={handleMoveProduct}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
