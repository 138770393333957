import { useState, useEffect } from 'react';

import SimpleAccordion from '../Accordian';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Typography,
} from '@mui/material';

import { useAuth } from '../../../utils/Auth';
import { useCms } from '../../../utils/CMS';

const ProductGenerate = ({
  selectedLoras,
  checkpoint,
  gender,
  species,
  setisLoading,
  setResultImage,
  setResultSeeds,
  setStarted,
  settotalJobs,
  setproductGeneration,
  setBatchStatus,
  setproductsCount,
  setLoraThumbsList,
}) => {
  const { generateMobile, username, getLoraThumb } = useAuth();
  const { getAllGroups, getPrompts } = useCms();

  const [selectedProduct, setselectedProduct] = useState('');
  const [products, setproducts] = useState([]);
  const [prompts, setprompts] = useState('');

  useEffect(() => {
    getAllGroups().then((groups) => {
      const gs = groups.filter((group) => group);
      setproducts(gs.map((g) => g.name));
    });
  }, []);

  useEffect(() => {
    selectedProduct &&
      getPrompts().then((resp) => {
        const product_prompts = resp.msg.prompts.filter(
          (prompt) => prompt.parent === selectedProduct
        ).length;
        setprompts(product_prompts);
        setproductsCount(product_prompts);
      });
  }, [products, selectedProduct]);

  const handleGenerate = () => {
    setproductGeneration(true);
    setResultImage([]);
    setResultSeeds([]);
    setLoraThumbsList([]);

    settotalJobs(prompts * selectedLoras.length);
    selectedLoras.forEach((lora) => {
      const data = {
        username: username.split('@')[0],
        product: selectedProduct,
        lora: lora.name,
        gender: gender,
        species: species,
      };

      generateMobile(data).then((resp) => {
        console.log(resp);
        console.log(resp.task_id.length);

        setisLoading(true);
        setStarted(true);

        getLoraThumb(lora.name).then((thumb) => {
          for (let x = 0; x < prompts; x++) {
            setLoraThumbsList((current) => ({
              ...current,
              [resp.task_id[x]]: thumb,
            }));
          }
        });
      });
    });
    setBatchStatus(1);
  };
  return (
    <SimpleAccordion title="Generate product" expanded={true}>
      <Box
        sx={{
          mt: 1,
          width: '420px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <FormControl fullWidth className="ring-0">
          <InputLabel id="demo-simple-select-label">Product</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="category"
            value={selectedProduct}
            label="Product"
            onChange={(e) => setselectedProduct(e.target.value)}
            size="small"
            sx={{ mt: '5px', width: '90%' }}
          >
            {products.map((group, index) => {
              return (
                <MenuItem key={index} value={group}>
                  {group}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          mt: 1,
          width: '420px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 1,
        }}
      >
        <Button
          variant="green"
          color="primary"
          sx={{ mt: 3, mb: 2, width: '200px' }}
          disabled={
            selectedLoras.length !== 0
              ? checkpoint === ''
                ? true
                : false
              : true
          }
          onClick={handleGenerate}
        >
          {' '}
          GENERATE
        </Button>
        {prompts !== '' && (
          <Typography sx={{ mt: '3px' }}>prompts: {prompts}</Typography>
        )}
      </Box>
    </SimpleAccordion>
  );
};

export default ProductGenerate;
