import { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';

import { IconButton, TextField } from '@mui/material';

import { useCms } from '../../../utils/CMS';
import SmallDialog from '../SmallDialog';
import Toast from '../../Toast';
import Dropdown from '../Generate/Dropdown';
import { AddAPhoto } from '@mui/icons-material';
import ProductImages from './ProductImages';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 80,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(255, 255, 255)'
        : 'rgb(255, 255, 255)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: 'rgb(255, 255, 255)',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function GroupMenu({
  group,
  index,
  cat_index,
  update,
  add,
  cats,
  handleMoveProduct,
}) {
  const { updateSubCategory, removeProduct, getTree, changeSubCategoryParent } =
    useCms();
  const [alert, setAlert] = useState({
    show: false,
    severity: 'success',
    msg: '',
  });

  const [renameSubCategoryOpen, setRenameSubCategoryOpen] = useState(false);
  const [renameSubCategoryData, setRenameSubCategoryData] = useState(false);
  const [subCategoryName, setSubCategoryName] = useState();

  const [status, setstatus] = useState(group.isProduction);
  const [price, setprice] = useState(group.price);

  const [category, setcategory] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (group) {
      setstatus(group.production ? 'released' : 'not released');
      setprice(group.price);
      setcategory(group.parent);
    }
  }, [group]);

  // RENAME SUB CATEGORY
  useEffect(() => {
    if (renameSubCategoryData) {
      let oldname = group.name;
      var newgroup = JSON.parse(JSON.stringify(group));
      newgroup.name = subCategoryName;
      newgroup.prompts = [];

      updateSubCategory(newgroup, oldname).then((resp) => {
        if (resp.success) {
          update('name', subCategoryName, index);
          setRenameSubCategoryData(false);
        } else {
          setAlert({
            show: true,
            severity: 'error',
            msg: resp.msg,
          });
        }
      });
    }
  }, [renameSubCategoryData]);

  // useEffect(() => {
  //   handleProductStatusChange();
  // }, [status]);

  const handleSubCategoryNameChange = (event) => {
    setSubCategoryName(event.target.value);
  };

  const handleRemoveProduct = () => {
    removeProduct(group.name).then((resp) => {
      if (resp.success) {
        setAlert({
          show: true,
          severity: 'success',
          msg: resp.msg,
        });
        update('delete', '', index);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  const handleProductStatusChange = (value) => {
    setstatus(value);
    var newgroup = JSON.parse(JSON.stringify(group));
    value === 'released'
      ? (newgroup.production = true)
      : (newgroup.production = false);
    updateSubCategory(newgroup, group.name).then((resp) => {
      if (resp.success) {
        update('production', newgroup.production, index);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };
  const handleProductPriceChange = (value) => {
    setprice(value);
    var newgroup = JSON.parse(JSON.stringify(group));
    newgroup.price = value;
    updateSubCategory(newgroup, group.name).then((resp) => {
      if (resp.success) {
        update('price', value, index);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  const handleProductCategoryChange = (value) => {
    setcategory(value);
    changeSubCategoryParent(group.name, value).then((resp) => {
      if (resp.success) {
        update('delete', '', index);
        var temp_group = { ...group };
        temp_group.parent = value;
        handleMoveProduct(temp_group, cat_index);
      } else {
        setAlert({
          show: true,
          severity: 'error',
          msg: resp.msg,
        });
      }
    });
  };

  const showToast = () => {
    setAlert({
      show: true,
      severity: 'error',
      msg: 'resp.msg',
    });
  };

  return (
    <div>
      <Toast alert={alert} setAlert={setAlert}></Toast>
      <IconButton aria-label="open" onClick={handleClick} variant="contained">
        <MenuOpenIcon fontSize="inherit" sx={{ color: '#fff' }} />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setRenameSubCategoryOpen(true)} disableRipple>
          <CreateIcon />
          Rename
        </MenuItem>
        <MenuItem onClick={() => handleRemoveProduct()} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem>
        <MenuItem disableRipple>
          <ProductImages name={group.name} />
        </MenuItem>
        <SmallDialog
          title="Rename subcategory"
          content=""
          open={renameSubCategoryOpen}
          setOpen={setRenameSubCategoryOpen}
          setData={setRenameSubCategoryData}
          cancelButton="Cancel"
          confirmButton="Rename"
          blocked={false}
        >
          <TextField
            margin="normal"
            id="promptName"
            label="name"
            name="name"
            value={subCategoryName}
            onChange={handleSubCategoryNameChange}
            className="ring-0"
            sx={{ width: '300px' }}
            size="small"
          />
        </SmallDialog>
        {/* <MenuItem onClick={handleRemoveCategory} disableRipple>
          <DeleteIcon />
          Delete
        </MenuItem> */}
        <MenuItem disableRipple>
          <Dropdown
            name="category"
            value={category}
            setValue={handleProductCategoryChange}
            items={cats}
            width="140px"
          />
        </MenuItem>
        <MenuItem disableRipple>
          <Dropdown
            name="status"
            value={status}
            setValue={handleProductStatusChange}
            items={['released', 'not released']}
            width="140px"
          />
        </MenuItem>
        <MenuItem disableRipple>
          <TextField
            margin="normal"
            id="price"
            label="price"
            name="price"
            value={price}
            onChange={(e) => setprice(e.target.value)}
            onBlur={(e) => handleProductPriceChange(e.target.value)}
            className="ring-0"
            sx={{ width: '140px', margin: '0px 0px' }}
            size="small"
          />
        </MenuItem>
      </StyledMenu>
    </div>
  );
}
