import { useEffect, useState } from 'react';
import Datagrid from './ReportsList';

import { useCms } from '../../../utils/CMS';

const Reports = () => {
  const { getReports } = useCms();
  const [rows, setrows] = useState([]);
  const [loading, setloading] = useState(true);

  //   useEffect(() => {
  //     async function gr() {
  //       const reports = await getReports();
  //       console.log(reports);
  //       const rr = reports.reports.map((r, index) => {
  //         var temp = { ...r };
  //         temp.feedback = r.feedback.message;
  //         temp.user = r.user.email;
  //         return temp;
  //       });
  //       console.log(rr);
  //       setrows(rr);
  //     }
  //     if (rows.length === 0) {
  //       gr();
  //     }
  //   }, []);

  return (
    <>
      {rows.length !== 0 ? (
        <div>loading...</div>
      ) : (
        <Datagrid rows={rows}></Datagrid>
      )}
    </>
  );
};

export default Reports;
