import { useState, useEffect } from 'react';
import Rating from '@mui/material/Rating';
import { Box, Typography } from '@mui/material';

import { useCms } from '../../../utils/CMS';

export default function PromptRating({
  promptName,
  name,
  value,
  update,
  index,
}) {
  const { updatePrompt } = useCms();
  const [rate, setRate] = useState(0);

  useEffect(() => {
    setRate(value);
  }, [value]);

  const handleUpdate = (newValue) => {
    var key = '';
    name === 'creator' ? (key = 'creator_rating') : (key = 'reviewer_rating');
    var p = { name: promptName };
    p[key] = newValue;
    updatePrompt(p).then((resp) => {
      if (resp.success) {
        setRate(newValue);
        // update(index, key, newValue);
      }
    });
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
        <Typography sx={{ fontSize: '13px' }}>{name}</Typography>

        <Rating
          name="simple-controlled"
          value={rate}
          onChange={(event, newValue) => {
            handleUpdate(newValue);
          }}
          size="small"
          sx={{ marginTop: '0px' }}
        />
      </Box>
    </>
  );
}
