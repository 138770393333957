import { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import PromptRating from './PromptRating';
import PromptMenu from './PromptMenu';
import { stableSort, getComparator } from './utils';
import {
  AddComment,
  Delete,
  InsertComment,
  PlusOne,
} from '@mui/icons-material';
import SmallDialog from '../SmallDialog';
import { TextField } from '@mui/material';

import { useAuth } from '../../../utils/Auth';
import { useCms } from '../../../utils/CMS';
import { getDateAndTime } from '../../../utils/generate';

const Row = ({ row, index, handleRowUpdate, setPromptToLoad, setTree }) => {
  const { username, isAdmin } = useAuth();
  const { updatePrompt } = useCms();

  const [rowx, setrowx] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const [commentOpen, setcommentOpen] = useState(false);
  const [commentData, setcommentData] = useState(false);

  const commentRef = useRef();

  useEffect(() => {
    if (commentData) {
      var oldNotes = [...rowx.note];
      var note = {
        note: commentRef.current.value,
        author: username,
        createdAt: getDateAndTime(),
      };
      oldNotes.unshift(note);
      var newRow = { ...rowx };
      newRow.note = oldNotes;
      var p = { name: rowx.name };
      p['note'] = oldNotes;
      updatePrompt(p).then((resp) => {
        if (resp.success) {
          setcommentData(false);
          setrowx(newRow);
        }
      });
    }
  }, [commentData]);

  const handleDeleteNote = (index) => {
    var oldNotes = [...rowx.note];
    oldNotes.splice(index, 1);
    var newRow = { ...rowx };
    newRow.note = oldNotes;
    var p = { name: rowx.name };
    p['note'] = oldNotes;
    updatePrompt(p).then((resp) => {
      if (resp.success) {
        setrowx(newRow);
      }
    });
  };

  useEffect(() => {
    setrowx(row);
    Object.keys(row).length !== 0 && setloading(false);
  }, [row]);

  if (loading) {
    return (
      <Box
        sx={{
          width: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        loading
      </Box>
    );
  } else {
    return (
      <>
        <TableRow key={rowx.name + index}>
          <TableCell>
            {rowx['note'].length !== 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </TableCell>
          <TableCell component="th" scope="row">
            {rowx.name}
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            align="center"
            style={{ width: 160 }}
          >
            {'category' in rowx && rowx.category.includes(' - ')
              ? rowx.category.split(' - ')[0]
              : rowx.category}
          </TableCell>
          <TableCell component="th" scope="row" align="center">
            {rowx.parent}
          </TableCell>
          <TableCell align="center">
            <img
              src={`data:image/jpeg;base64,${rowx.thumb}`}
              alt=""
              width="64"
            />
          </TableCell>
          <TableCell style={{ width: 200 }} align="center">
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              {Object.keys(rowx.prompts).map((key) => {
                if (rowx.prompts[key] !== '') {
                  return (
                    <Typography sx={{ fontSize: '14px' }}>
                      {key.replace('_', ' ')}
                    </Typography>
                  );
                }
              })}
            </Box>
          </TableCell>
          {/* <TableCell style={{ width: 100 }} align="center">
            {'gender' in rowx ? rowx.gender : 'male'}
          </TableCell> */}
          <TableCell style={{ width: 160 }} align="center">
            {rowx.author}
          </TableCell>
          <TableCell style={{ width: 160 }} align="center">
            {rowx.modified_by}
          </TableCell>
          <TableCell style={{ width: 160 }} align="center">
            {rowx.createdAt}
          </TableCell>
          <TableCell style={{ width: 160 }} align="center">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PromptRating
                promptName={rowx.name}
                name="creator"
                value={rowx.creator_rating}
                index={index}
                update={handleRowUpdate}
              />
              <Divider sx={{ backgroundColor: ' #ff6000', width: '140px' }} />
              <PromptRating
                promptName={rowx.name}
                name="reviewer"
                value={rowx.reviewer_rating}
                index={index}
                update={handleRowUpdate}
              />
            </Box>
          </TableCell>
          <TableCell style={{ width: 100 }} align="center">
            {'isProduction' in rowx ? rowx.isProduction : 'No'}
          </TableCell>
          <TableCell style={{ width: 160 }} align="center">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                gap: '10px',
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  setPromptToLoad(rowx);
                }}
              >
                load
              </Button>
              {(rowx.author === username || isAdmin) && (
                <PromptMenu
                  name={rowx.name}
                  setTree={setTree}
                  species={'species' in rowx ? rowx.species : 'human'}
                  gender={'gender' in rowx ? rowx.gender : 'male'}
                  isProduction={
                    'isProduction' in rowx ? rowx.isProduction : 'No'
                  }
                  index={index}
                  update={handleRowUpdate}
                ></PromptMenu>
              )}

              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setcommentOpen(true)}
              >
                <InsertComment
                  sx={{ color: '#fff', fontSize: '20px' }}
                ></InsertComment>
              </IconButton>
            </Box>
          </TableCell>
        </TableRow>
        {rowx['note'].length !== 0 && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={13}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Comments
                      <p style={{ color: '#ff6000', fontSize: '13px' }}>
                        {rowx.name}
                      </p>
                    </Typography>
                  </Box>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow sx={{ backgroundColor: '#131313' }}>
                        <TableCell>Comment</TableCell>
                        <TableCell align="center">Author</TableCell>
                        <TableCell align="center">Created at</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowx['note'].reverse().map((n, idx) => (
                        <TableRow key={n.note + idx}>
                          <TableCell component="th" scope="row" align="left">
                            <Typography>{n.note}</Typography>
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {n.author}
                          </TableCell>
                          <TableCell align="center">{n.createdAt} </TableCell>
                          <TableCell align="center">
                            <IconButton onClick={() => handleDeleteNote(idx)}>
                              <Delete sx={{ color: '#ff6000' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
        <SmallDialog
          title="Add comment"
          content=""
          open={commentOpen}
          setOpen={setcommentOpen}
          setData={setcommentData}
          confirmButton="Add"
          cancelButton="Cancel"
          blocked={false}
          fullwidth={true}
          maxwidth="md"
        >
          <TextField
            margin="normal"
            id="comment"
            label="comment"
            name="comment"
            inputRef={commentRef}
            className="ring-0"
            sx={{ width: '100%' }}
            size="small"
            multiline
            rows={5}
          />
        </SmallDialog>
      </>
    );
  }
};

export default Row;
