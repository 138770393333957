import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar, useGridApiRef } from '@mui/x-data-grid';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import { useCms } from '../../../utils/CMS';
import ContextMenu from './ContextMenu';
import Compare from './Compare';
import Dropdown from '../Generate/Dropdown';
import { TextWrapCellRenderer, ImageCell, Progress } from '../SmallComponents';

function formatDate(inputString) {
  // Parse the input string into a Date object
  const dateObj = new Date(inputString);

  // Format the date components
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');

  // Construct the formatted date string
  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

  return formattedDate;
}

const typesDropdown = (props) => {
  const { item, applyValue, focusElementRef } = props;

  const items = [
    'there is no avatar in the image',
    'the image is broken',
    'no similarity',
    'other issues',
  ];

  const handleFilterChange = (event, newValue) => {
    applyValue({ ...item, value: newValue });
  };

  return (
    <FormControl className="ring-0">
      <InputLabel id="demo-simple-select-label">{'types'}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id={'types'}
        value={item.value}
        label={'types'}
        onChange={(e) => handleFilterChange(e, e.target.value)}
        size="small"
        sx={{ mt: '5px', width: '140px' }}
      >
        {items.map((item) => {
          return <MenuItem value={item}>{item}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

const typeFilterOperator = [
  {
    label: '==',
    value: 'equals',
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null;
      }

      return (params) => {
        return params.value === filterItem.value;
      };
    },
    InputComponent: typesDropdown,
    InputComponentProps: { type: 'string' },
    getValueAsString: (value) => `${value}`,
  },
];

const columns = [
  {
    field: 'reviewed',
    headerName: 'reviewed',
    width: 80,
    type: 'boolean',
  },
  {
    field: 'type',
    headerName: 'type',
    width: 140,
    type: 'string',
    renderCell: TextWrapCellRenderer,
    filterOperators: typeFilterOperator,
  },
  {
    field: 'user',
    headerName: 'user',
    width: 240,
    type: 'string',
  },
  {
    field: 'feedback',
    headerName: 'feedback',
    width: 440,
    renderCell: TextWrapCellRenderer,
  },
  { field: 'product', headerName: 'product', width: 140 },
  { field: 'prompt', headerName: 'prompt', width: 150, editable: false },
  {
    field: 'image',
    headerName: 'image',
    width: 150,
    editable: false,
    renderCell: ImageCell,
  },

  { field: 'lora', headerName: 'lora', width: 300, editable: false },
  {
    field: 'reported_at',
    headerName: 'reported at',
    width: 100,
    editable: false,
    renderCell: TextWrapCellRenderer,
  },
  {
    field: 'generated_at',
    headerName: 'generated at',
    width: 170,
    editable: false,
  },
  { field: 'job', headerName: 'job', width: 300, editable: false, hide: true },
  {
    field: 'order',
    headerName: 'order',
    width: 300,
    editable: false,
    hide: true,
  },
  {
    field: 'index',
    headerName: 'index',
    width: 50,
    editable: false,
    hide: true,
  },
];

export default function Datagrid() {
  const apiRef = useGridApiRef();

  const { getReports, updateReport } = useCms();
  const [rows, setrows] = useState([]);
  const [compareDialogOpen, setCompareDialogOpen] = useState(false);
  const [compareImages, setcompareImages] = useState([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    async function gr() {
      const reports = await getReports();
      const rr = reports.reports.map((r, index) => {
        var temp = { ...r };
        temp.feedback = r.feedback.message;
        temp.type = r.feedback.type;
        temp.user = r.user.email;
        temp.id = index;
        temp.reported_at = formatDate(r.reported_at);
        temp.generated_at = formatDate(r.generated_at);
        temp.index = r.image;
        temp.image = `https://devrest.garagefarm.net/images/${r.job}/${r.image}?user=${r.user.name}`;

        return temp;
      });
      setrows(rr);
    }
    if (rows.length === 0) {
      gr();
    }
  }, []);

  useEffect(() => {
    rows.length !== 0 && setloading(false);
  }, [rows]);

  const getRowHeight = (params) => {
    const lineHeight = 20; // Adjust this value based on your font size and styling
    const lines = Math.ceil(params.model.feedback.length / 50); // Adjust the number of characters per line as needed
    var h = lines * lineHeight + 16;
    if (h < 100) {
      return 100;
    } else {
      return h;
    }
  };

  const handleRowUpdate = () => {
    console.log('handleRowUpdate');
  };
  const handleOnCellClick = (params) => {
    if (params.field === 'image') {
      window.open(params.value, '_blank', 'noreferrer');
    }
  };

  const [contextMenu, setContextMenu] = useState(null);
  const [rowClicked, setrowClicked] = useState(null);
  const handleRowContextMenu = (event) => {
    event.preventDefault();
    const rowId = Number(event.currentTarget.getAttribute('data-id'));
    setrowClicked(rowId);

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const markAsCompleted = () => {
    const record = rows.find((row) => row.id === rowClicked);
    console.log(apiRef.current.getRow(rowClicked));
    const r = apiRef.current.getRow(rowClicked);
    if (!r.reviewed) {
      updateReport({ q: { uuid: record.uuid }, s: { reviewed: true } }).then(
        (resp) => {
          if (resp.status === 'success') {
            apiRef.current.updateRows([{ id: rowClicked, reviewed: true }]);
          }
        }
      );
    } else {
      updateReport({ q: { uuid: record.uuid }, s: { reviewed: false } }).then(
        (resp) => {
          if (resp.status === 'success') {
            apiRef.current.updateRows([{ id: rowClicked, reviewed: false }]);
          }
        }
      );
    }
  };
  const copyPath = () => {
    const record = rows.find((row) => row.id === rowClicked);
    const path = `u:\\a0000009\\Gleem\\Generation\\${
      record.user.split('@')[0]
    }\\${record.job}\\output`;
    navigator.clipboard.writeText(path);
  };

  const openCompareDialog = () => {
    const r = apiRef.current.getRow(rowClicked);
    const loraImage = `https://devrest.garagefarm.net/${
      r.user.split('@')[0]
    }/avatar/${r.lora}`;
    const generationImage = r.image;
    setcompareImages([loraImage, generationImage]);
    setCompareDialogOpen(true);
  };

  return (
    <>
      <Box sx={{ height: '90vh', width: '100%' }}>
        <DataGrid
          apiRef={apiRef}
          rows={rows}
          columns={columns}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
            row: {
              onContextMenu: handleRowContextMenu,
              style: { cursor: 'context-menu' },
            },
            loadingOverlay: Progress,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 50,
              },
            },
            filter: {
              filterModel: {
                items: [
                  {
                    id: 1,
                    field: 'reviewed',
                    value: 'false',
                    operator: 'is',
                  },
                ],
                quickFilterExcludeHiddenColumns: true,
              },
            },
            columns: {
              columnVisibilityModel: {
                index: false,
                job: false,
                order: false,
                generated_at: false,
              },
            },
          }}
          getRowHeight={getRowHeight}
          pageSizeOptions={[50]}
          disableDensitySelector
          onCellClick={handleOnCellClick}
          loading={loading}
        />
      </Box>
      <ContextMenu
        contextMenu={contextMenu}
        handleClose={handleClose}
        markAsCompleted={markAsCompleted}
        copyPath={copyPath}
        openCompareDialog={openCompareDialog}
      />
      <Compare
        images={compareImages}
        open={compareDialogOpen}
        setOpen={setCompareDialogOpen}
      />
    </>
  );
}

// function CustomToolbar() {
//   return (
//     <GridToolbarContainer>
//       <GridToolbarQuickFilter />
//       <GridToolbarColumnsButton sx={{ color: '#fff' }} />
//       <GridToolbarFilterButton sx={{ color: '#fff' }} />
//       <GridToolbarDensitySelector sx={{ color: '#fff' }} />
//       <GridToolbarExport sx={{ color: '#fff' }} />
//     </GridToolbarContainer>
//   );
// }
