import * as React from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';

export default function ContextMenu({
  contextMenu,
  handleClose,
  markAsCompleted,
  copyPath,
  openCompareDialog,
}) {
  return (
    <Paper sx={{ width: 320 }}>
      <Menu
        id="basic-menu"
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        open={contextMenu !== null}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        <MenuItem>
          <ListItemText onClick={markAsCompleted}>Change status</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText onClick={copyPath}>
            Copy path to generation
          </ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemText onClick={openCompareDialog}>Compare</ListItemText>
        </MenuItem>
      </Menu>
    </Paper>
  );
}
