import { Box, Button, TextField } from '@mui/material';
import { useState, useEffect } from 'react';

const TestRoute = () => {
  const [started, setstarted] = useState(false);
  const [data, setdata] = useState('');
  const [channel, setChannel] = useState('');

  useEffect(() => {
    if (started) {
      console.log('subscribe ');
      const source = new EventSource(
        `https://gleem.garagefarm.net/events?channel=test`,
        { withCredentials: false }
      );

      source.addEventListener('open', () => {
        console.log('SSE opened!');
      });

      source.addEventListener('message', (e) => {
        console.log(e.data);
        const data = JSON.parse(e.data);

        setdata(data);
      });

      source.addEventListener('error', (e) => {
        console.error('Error: ', e);
        source.close();
      });
    }
  }, [started]);

  return (
    <>
      <Box
        sx={{
          //   position: 'absolute',
          //   top: '50%',
          //   left: '50%',

          display: ' flex',
          flexDirection: 'column',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            width: '200px',
            display: ' flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            sx={{ backgroundColor: '#000' }}
            value={channel}
            onChange={(e) => setChannel(e.target.value)}
          ></TextField>
          <Button variant="contained" onClick={() => setstarted(true)}>
            start listen
          </Button>
          <div>data: {data.message}</div>
        </Box>
      </Box>
    </>
  );
};

export default TestRoute;
