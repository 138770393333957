import { createTheme } from '@mui/material/styles';

const main = '#FF6000';
const secondary = '#cf550c';

export const mainTheme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: main,
      light: '#fc0303',
      dark: '#fc0303',
      contrastText: '#fff',
    },
    secondary: {
      main: secondary,
    },
    background: {
      default: '#000',
      paper: ' #5E5E5E',
    },
    text: {
      secondary: 'rgba(0,0,0,0.7)',
      disabled: 'rgba(210,7,7,0.5)',
      primary: '#fff',
      hint: 'rgba(255,57,57,0.5)',
    },
  },

  components: {
    // CTRL + SPACE to find the component you would like to override.
    // For most of them you will need to adjust just the root...
    MuiButton: {
      variants: [
        {
          props: { variant: 'dashboard' },
          style: {
            textTransform: 'none',
            border: `1px solid #000`,
            backgroundColor: `${secondary} !important`,
            '&:hover': {
              backgroundColor: `${main} !important`,
            },
          },
        },
        {
          props: { variant: 'blue' },
          style: {
            textTransform: 'none',
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
            backgroundColor: `#009fff !important`,
            '&:hover': {
              backgroundColor: `#0087d8 !important`,
            },
          },
        },
        {
          props: { variant: 'green' },
          style: {
            color: '#000',
            textTransform: 'none',
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
            backgroundColor: `#01f11a !important`,
            '&:hover': {
              backgroundColor: `#01d817 !important`,
            },
          },
        },
        {
          props: { variant: 'menu' },
          style: {
            textTransform: 'none',
            color: '#fff',
            width: '100%',
            backgroundColor: `rgba(0, 0, 0, 0) !important`,
            '&:hover': {
              backgroundColor: `rgba(0, 0, 0, 0.1) !important`,
            },
          },
        },
        {
          props: { variant: 'menuActive' },
          style: {
            textTransform: 'none',
            color: '#fff',
            width: '100%',
            backgroundColor: `${main} !important`,
            '&:hover': {
              backgroundColor: `${secondary} !important`,
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: `${main} !important`,
          '&:hover': {
            backgroundColor: `${secondary} !important`,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 100px #000 inset',
            '-webkit-text-fill-color': '#fecd27',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#fff',
          '& label': {
            color: '#fff',
          },
          '& label.Mui-focused': {
            color: '#fff',
          },
        },
      },
    },
    MuiSelectOutlined: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&:hover ': {
            borderColor: '#3E68A8',
            borderWidth: '0.15rem',
            color: '#fff',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#fecd27',
        },
      },
    },
  },
});
